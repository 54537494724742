export const hubsConstants =
{

    FETCH_HUBS_REQUEST: "FETCH_HUBS_REQUEST",
    FETCH_HUBS_SUCCESS: "FETCH_HUBS_SUCCESS",
    FETCH_HUBS_FAILURE: "FETCH_HUBS_FAILURE",

    FETCH_HUBS_BY_STATE_REQUEST: "FETCH_HUBS_BY_STATE_REQUEST",
    FETCH_HUBS_BY_STATE_SUCCESS: "FETCH_HUBS_BY_STATE_SUCCESS",
    FETCH_HUBS_BY_STATE_FAILURE: "FETCH_HUBS_BY_STATE_FAILURE",

    FETCH_HUBS_BY_CITY_REQUEST: "FETCH_HUBS_BY_CITY_REQUEST",
    FETCH_HUBS_BY_CITY_SUCCESS: "FETCH_HUBS_BY_CITY_SUCCESS",
    FETCH_HUBS_BY_CITY_FAILURE: "FETCH_HUBS_BY_CITY_FAILURE",

    FETCH_FADEYI_HUB_REQUEST: "FETCH_FADEYI_HUB_REQUEST",
    FETCH_FADEYI_HUB_SUCCESS: "FETCH_FADEYI_HUB_SUCCESS",
    FETCH_FADEYI_HUB_FAILURE: "FETCH_FADEYI_HUB_FAILURE",

    SET_STATUS: "SET_STATUS"

};
