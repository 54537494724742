import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from "./ButtonLink.module.scss";

const ButtonLink = ( {
    path,
    isMail,
    isExt,
    outline,
    onClick,
    className,
    children,
    ...props
} ) => 
{
    if ( isMail )
    {
        return (
            <a
                href={ path }
                className={
                    classNames( styles.btnLink,
                        {
                            [ styles.outline ]: outline
                        },
                        className ) }
                { ...props }>
                { children }
            </a>
        );
    }
    else if ( path )
    {
        return (
            <NavLink
                to={ path }
                className={
                    classNames( styles.btnLink,
                        {
                            [ styles.outline ]: outline
                        },
                        className ) }
                { ...props }>
                { children }
            </NavLink>
        );
    }
    else
    {
        return (
            <button
                onClick={ onClick }
                className={
                    classNames( styles.btnLink,
                        {
                            [ styles.outline ]: outline
                        },
                        className ) }
                { ...props }>
                { children }
            </button>
        );
    }
};

export { ButtonLink };
