// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Retry_retry__1LY_3 {\n  background-color: #f76060;\n  padding: 10px;\n  font-size: 18px;\n  border: none;\n  display: inline-block;\n  color: #fff;\n  border-radius: 4px;\n  min-width: 120px;\n  margin: 20px 0;\n}", "",{"version":3,"sources":["webpack://src/fromOld/components/Retry/Retry.module.scss"],"names":[],"mappings":"AAEA;EAEI,yBAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":["@import \"../../assets/scss/index.scss\";\n\n.retry\n{\n    background-color: $error;\n    padding:10px;\n    font-size: 18px;\n    border: none;\n    display: inline-block;\n    color: #fff;\n    border-radius: 4px;\n    min-width: 120px;\n    margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retry": "Retry_retry__1LY_3"
};
export default ___CSS_LOADER_EXPORT___;
