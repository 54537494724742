import { ordersConstants } from "../constants";

import { request, failure } from "../../utils/helpers";

const initialState = {
  loading: false,
  orders: [],
  error: null,
  status: "idle",

  collections: [],
  collStatus: "idle",
  collErr: null,
};

export function orders(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ordersConstants.FETCH_USER_ORDERS_REQUEST:
      return request(state);

    case ordersConstants.FETCH_USER_COLL_ORDERS_REQUEST:
      return {
        ...state,
        collStatus: "pending",
        collErr: null,
      };

    case ordersConstants.FETCH_USER_ORDERS_FAILURE:
      return failure(state, action.payload);

    case ordersConstants.FETCH_USER_COLL_ORDERS_FAILURE:
      return {
        ...state,
        collStatus: "rejected",
        collErr: payload,
      };

    case ordersConstants.FETCH_USER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
        status: "resolved",
      };

    case ordersConstants.FETCH_USER_COLL_ORDERS_SUCCESS:
      return {
        ...state,
        collStatus: "resolved",
        collections: payload,
      };

    default:
      return state;
  }
}
