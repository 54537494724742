import React from 'react';

import 
{
    Container,
    Header,
    Stats,
    Subscribe,
} from "@components";

import styles from './About.module.scss';

import who from '@images/who.png';
import what from '@images/what.png';
import why from '@images/why.png';


const items =
    [
        {
            text: "ABC Cargo Express, is a member of the ABC Transport Plc. Group, Nigeria’s leading road transport and logistics company covering cities in Nigeria and West Africa sub-region. For over 26 year, ABC Cargo Express has developed cutting edge, fast, efficient and cost saving parcel delivery services for all categories of businesses and individuals",
            title: "Who are we",
            img: who
        },
        {
            text: "Our track record in the areas of consolidated cargo delivery, light parcel / document express door delivery, warehousing and last mile distribution, e-commerce logistics support service are unparalleled.",
            title: "What we do",
            img: what
        },
        {
            text: "Our excellent and technology driven processes couple with top notch professionalism in shipment handling procedure definitely makes it possible for you to BOOK your cargo seamlessly. TRACK it in real time and RECEIVE it in perfect condition",
            title: "Why choose us",
            img: why
        },
    ];


const About = () =>
{
    return (
        <>


            <Header center className={ styles.header }>
                About us
            </Header>

            <Container className={ styles.wrapper }>

                {
                    items.map( ( it, i ) => (
                        <article key={ i } className={ styles.card }>


                            <section className={ styles.content }>
                                <div className={ styles.title }>
                                    <div className={ styles.bullet }>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div>
                                    <Header className={ styles.head }>{ it.title }</Header>
                                </div>
                                <p>{ it.text }</p>
                            </section>

                            <div className={ styles.img } >
                                <img src={ it.img } alt={ it.title } />
                            </div>

                        </article>
                    ) )
                }

            </Container>



            <Stats />


            <Subscribe />

        </>
    );
};

export default About;
