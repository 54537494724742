export const cityStateConstants =
{

    FETCH_CITIES_REQUEST: "FETCH_CITIES_REQUEST",
    FETCH_CITIES_SUCCESS: "FETCH_CITIES_SUCCESS",
    FETCH_CITIES_FAILURE: "FETCH_CITIES_FAILURE",

    FETCH_STATES_REQUEST: "FETCH_STATES_REQUEST",
    FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
    FETCH_STATES_FAILURE: "FETCH_STATES_FAILURE",


    SET_STATUS: "SET_STATUS"

};
