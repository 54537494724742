import classNames from "classnames";

import styles from './Text.module.scss';


const LinedText = ( { children, className, ...props } ) =>
{
    return (
        <h4 className={ classNames( styles.lined, className ) }
            { ...props }>
            <span>{ children }</span>
        </h4>
    );
};

export { LinedText };
