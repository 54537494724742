import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { makeRequest } from "@utils";

import {
  Container,
  Header,
  Inputs,
  ButtonLink,
  FormContainer,
} from "@components";

import styles from "../Page.module.scss";

const schema = yup
  .object()
  .shape({
    //isBiz: yup.bool(),

    email: yup.string().email("Please input a valid email"),
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], "Password must match"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .isValidPhone(),
    businessName: yup.string().when("isBiz", {
      is: (value) => !!value,
      then: yup.string().required("Business name is required"),
    }),
  })
  .required();

const hasError = (errors) => {
  const field = [
    "email",
    "password",
    "phoneNumber",
    "businessName",
    "passwordConfirmation",
  ];

  const err = field.reduce((acc, item) => {
    if (errors[item] && errors[item].message) {
      acc = true;
    }
    return acc;
  }, false);

  return err;
};

const EcommerceForm = () => {
  const navigate = useNavigate();
  const [create, setCreate] = useState({
    status: "idle",
    error: null,
    data: null,
  });
  const {
    register,

    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    const form = {
      ...values,
      businessName: values.businessName,
      firstName: values.businessName,
      lastName: values.businessName,
      customerTypeId: "Regular",
      sourceId: "WEB",
      isRequestingEcommerce: true,
      userName: values.email,
      email: values.email,
      phoneNumber: values.phoneNumber.replace("+", ""),
      customerGroupId: "e-commerce",
    };

    setCreate({
      status: "pending",
      error: null,
      data: null,
    });

    const data = await makeRequest({
      path: `Customers/CreateCustomerAccount`,
      method: "POST",
      body: form,
    });

    if (data.error) {
      setCreate({
        status: "rejected",
        error: data.error.toString(),
        data: null,
      });
    } else {
      window.scrollTo(0, 0);

      setCreate({
        status: "resolved",
        error: null,
        data: "Ecommerce Customer created, you will be redirected to login in 5 seconds",
      });

      setTimeout(() => {
        navigate("/login");
      }, 5 * 1000);
    }
  };

  return (
    <>
      <Container>
        <Header center>Register your Business</Header>
        <br />
        <FormContainer
          message={`Please provide some information about your business`}
          submitHandler={handleSubmit(onSubmit)}
          formClass={styles.formClass}
          autoComplete="off"
        >
          <br />

          {create.error && <p className={styles.formErr}>{create.error}</p>}

          {create.data && <p className={styles.success}>{create.data}</p>}

          <br />

          <div className={styles.ecommerceForm}>
            <Inputs
              inputProps={{
                ...register("businessName"),
                name: "businessName",
              }}
              label="Business Name"
              labelClass={styles.label}
              error={errors.businessName && errors.businessName?.message}
            />

            <Inputs
              inputProps={{
                ...register("email"),
                name: "email",
              }}
              label="Official E-mail"
              type="email"
              labelClass={styles.label}
              error={errors.email && errors.email?.message}
            />

            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Inputs
                  field={field}
                  type="tel"
                  inputProps={{
                    name: "phoneNumber",
                  }}
                  label="Contact Number"
                  labelClass={styles.label}
                  error={errors.phoneNumber && errors.phoneNumber?.message}
                />
              )}
            />

            <Inputs
              inputProps={{
                ...register("password"),
                name: "password",
                autoComplete: "new-password",
              }}
              type="password"
              label="Password"
              labelClass={styles.label}
              error={errors.password && errors.password?.message}
            />

            <Inputs
              inputProps={{
                ...register("passwordConfirmation"),
                name: "passwordConfirmation",
                autoComplete: "new-password",
              }}
              type="password"
              label="Confirm Password"
              labelClass={styles.label}
              error={
                errors.passwordConfirmation &&
                errors.passwordConfirmation?.message
              }
            />
          </div>

          <ButtonLink
            disabled={create.status === "pending" || hasError(errors)}
            className={styles.submit}
          >
            Register
          </ButtonLink>
        </FormContainer>
      </Container>
    </>
  );
};

export default EcommerceForm;
