import { hubsConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import { dispatcher, trimHubs } from "../../utils/helpers";

const getHubs = () => async ( dispatch ) =>
{
    dispatch( dispatcher( hubsConstants.FETCH_HUBS_REQUEST ) );


    const data = await makeRequest( {
        path: "Hubs/ViewAllHubs",
        method: "GET",
    } );


    if ( data.error )
    {
        dispatch( dispatcher( hubsConstants.FETCH_HUBS_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        dispatch( dispatcher( hubsConstants.FETCH_HUBS_SUCCESS, trimHubs( res ) ) );
    }
};

const getHubsByState = ( stateId ) => async ( dispatch ) =>
{

    dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_STATE_REQUEST ) );

    const data = await makeRequest( {
        path: `/Hubs/ViewHubsByState?StateId=${ stateId }`,
        method: "GET",
    } );


    if ( data.error )
    {
        dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_STATE_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_STATE_SUCCESS, trimHubs( res ) ) );
    }
};

const getHubsByCity = ( cityId ) => async ( dispatch ) =>
{

    dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_CITY_REQUEST ) );

    const data = await makeRequest( {
        path: `/Hubs/ViewHubsByCity?cityId=${ cityId }`,
        method: "GET",
    } );

    if ( data.error )
    {
        dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_CITY_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        dispatch( dispatcher( hubsConstants.FETCH_HUBS_BY_CITY_SUCCESS, trimHubs( res ) ) );
    }
};

const getFadeyiHub = () => async ( dispatch ) =>
{

    dispatch( dispatcher( hubsConstants.FETCH_FADEYI_HUB_REQUEST ) );

    const data = await makeRequest( {
        path: `/Hubs/ViewHubById?hubId=FDY`,
        method: "GET",
    } );


    if ( data.error )
    {
        dispatch( dispatcher( hubsConstants.FETCH_FADEYI_HUB_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );


        dispatch( dispatcher( hubsConstants.FETCH_FADEYI_HUB_SUCCESS, res ) );
    }
};

const setStatus = ( key, value ) => (
    {
        type: hubsConstants.SET_STATUS,
        payload:
        {
            key,
            value
        }
    }
);


export const hubActions =
{
    setStatus,
    getHubs,
    getHubsByState,
    getHubsByCity,
    getFadeyiHub,
};
