import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { HashLink } from "react-router-hash-link";
import { Container, ButtonLink } from "@components";

import styles from "./Footer.module.scss";

import logo from "@images/logooo.png";

const paths = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "About us",
    path: "/about",
  },
  {
    text: "Our services",
    path: "/services",
  },
  {
    text: "Our branches",
    path: "/hubs",
  },
  {
    text: "FAQ",
    path: "/faq",
  },
  {
    text: "Email Login",
    path: "https://secure353.inmotionhosting.com:2096",
    isExt: true,
  },
  {
    text: "Legal",
    path: "/legal",
  },
  {
    text: "Track Waybill",
    isHash: true,
    path: "/#track",
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container className={styles.wrapper}>
        <div>
          <NavLink to="/" className={styles.logo}>
            <img src={logo} alt="abc logo" />
          </NavLink>

          <small className={styles.smal}>
            {" "}
            &copy; Copyright 2021 ABC Cargo Express
          </small>
        </div>

        <ul className={classNames(styles.links)}>
          {paths.map((p) =>
            p.isExt ? (
              <li key={p.path}>
                <a target="_blank" rel="noreferrer" href={p.path}>
                  {p.text}
                </a>
              </li>
            ) : p.isHash ? (
              <li key={p.path}>
                <HashLink smooth to={p.path}>
                  {p.text}
                </HashLink>
              </li>
            ) : (
              <li key={p.path}>
                <NavLink to={p.path}>{p.text}</NavLink>
              </li>
            )
          )}
        </ul>

        <div className={styles.contact}>
          <NavLink to="/contact">Contact Us</NavLink>

          <p>Ikorodu Road Fadeyi, Lagos State. Nigeria</p>

          <p>cargoex@abctransport.com</p>

          <p>0813 986 2090, 08142552445, 080-63261919.</p>
        </div>

        <section className={styles.btnLinks}>
          <ButtonLink path="/login">Log in</ButtonLink>

          <ButtonLink path="/sign-up" outline>
            Sign up
          </ButtonLink>
        </section>
      </Container>
    </footer>
  );
};

export { Footer };
