const baseUrl = `https://api.abccargoxpress.com/api/v1`;

/**
 * This method is responsible for making fetch requests.
 * It returns an object with either a data or error property
 *@param {Object} reqOptions - Configuration for the request
 *@param {string | undefined} reqOptions.url -[Optional] The base url for request, defaults to app base url is not provided
 *@param {string | undefined} reqOptions.method -[Optional] The http method for the request, default is GET
 *@param {string} reqOptions.path -[Required] The path for the request.
 *@param {Object | undefined} reqOptions.body -[Optional] The body of the request
 *@param {Object | undefined} reqOptions.headers-[Optional] The headers needed for the request
 */

const makeRequest = async (reqOptions) => {
  let code;
  let headers;
  let endpoint = "";

  if (reqOptions.url) {
    endpoint = reqOptions.url;
  } else {
    const path =
      reqOptions.path[0] === "/" ? reqOptions.path.slice(1) : reqOptions.path;
    endpoint = `${reqOptions.url || baseUrl}/${path}`;
  }

  try {
    const token = JSON.parse(localStorage.getItem("abc-user"))?.token || "";

    if (reqOptions.noHeaders) {
    }

    const res = await fetch(`${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...reqOptions.headers,
      },
      method: reqOptions.method || "GET",
      body: JSON.stringify(reqOptions.body),
    });

    code = res.status;
    headers = res.headers;

    const data = await res.text();

    if (!res.ok) {
      return {
        error: data.toString() || "Unable to complete request",
        code,
      };
    }

    return { data };
  } catch (error) {
    if ((code <= 299) & (code >= 200)) {
      // returns 200, but no body
      return { data: "Success" };
    }

    let msg;

    if (headers && headers["WWW-Authenticate"]?.includes("The token expired")) {
      error.message =
        "Your session has expired, please sign in again. Thank you.";
    }

    msg =
      error.message !== "Failed to fetch"
        ? error.message || "Request Failure"
        : "Unable to complete request";
    return { error: msg };
  }
};

export { baseUrl, makeRequest };
