import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Header,
  Inputs,
  ButtonLink,
  MapItem,
  SelectSearchDropDown,
} from "@components";

import { Retry } from "../../../fromOld/components";

import { hubActions, cityStateActions } from "@redux/actions";

import styles from "./Hubs.module.scss";

const map = {
  state: true,
  city: false,
  true: "state",
  false: "city",
};

const transformOptions = (options) => {
  return options.map((o) => ({
    ...o,
    value: o.id,
  }));
};

const Hubs = () => {
  const dispatch = useDispatch();
  //const [isFadeyi, setIsFadeyi] = useState(true);

  const [isState, setIsState] = useState(null);
  const [val, setVal] = useState("");

  const {
    cityHubs,
    stateHubs,
    cityHubsStatus,
    stateHubsStatus,
    cityHubsErr,
    stateHubsErr,
  } = useSelector((state) => state.hubs);

  const { cities, states, citiesStatus, statesStatus, citiesErr, statesErr } =
    useSelector((state) => state.cityState);

  const { getHubsByState, getHubsByCity /*, getFadeyiHub*/ } =
    bindActionCreators(hubActions, dispatch);

  const { getCities, getStates } = bindActionCreators(
    cityStateActions,
    dispatch
  );

  const search = () => {
    // if (isFadeyi) {
    //   setIsFadeyi(false);
    // }

    if (isState && val) {
      getHubsByState(val);
    } else {
      getHubsByCity(val);
    }
  };

  const onModeChange = (e) => {
    const { value } = e.target;
    setIsState(map[value]);
    setVal("");
  };

  const onSelect = (value) => {
    setVal(value);
  };

  const retry = () => {
    if (citiesStatus === "rejected") {
      getCities();
      //getFadeyiHub();
    }

    if (statesStatus === "rejected") {
      getStates();
    }

    if (cityHubsStatus === "rejected" || stateHubsStatus === "rejected") {
      search();
    }
  };

  useEffect(() => {
    if (citiesStatus === "idle") {
      getCities();
      //getFadeyiHub();
    }

    if (statesStatus === "idle") {
      getStates();
    }
  }, [getCities, getStates, citiesStatus, statesStatus /*, getFadeyiHub*/]);

  let data = map[isState] === "state" ? stateHubs : cityHubs;

  if (data?.length) {
    data = data.filter((h) => h.isActive);
  }

  return (
    <>
      <br />

      <Header center>Our Branches</Header>

      <Container className={styles.wrapper}>
        <section className={styles.controls}>
          <div className={styles.item}>
            <Inputs
              wrapperClass={styles.field}
              inputClass={styles.select}
              inputProps={{
                name: "mode",
                fade: true,
                onChange: onModeChange,
                selectText: "Choose search mode",
                options: [
                  {
                    id: "hh-iii",
                    val: "city",
                    label: "Search by city",
                  },
                  {
                    id: "kk-lll",
                    val: "state",
                    label: "Search by state",
                  },
                ],
              }}
              type="select"
              value={map[isState]}
            />
          </div>

          <div className={classNames(styles.item, styles.search)}>
            <SelectSearchDropDown
              options={
                isState
                  ? statesStatus === "resolved"
                    ? transformOptions(states)
                    : []
                  : citiesStatus === "resolved"
                  ? transformOptions(cities)
                  : []
              }
              search={true}
              name={"Pick"}
              value={val}
              disabled={!map[isState]}
              onChange={onSelect}
              placeholder="Choose..."
            />
          </div>

          <div className={classNames(styles.item, styles.btn)}>
            <ButtonLink onClick={search} disabled={!(val && map[isState])}>
              Search
            </ButtonLink>
          </div>
        </section>

        <div className={styles.errWrap}>
          {isState ? (
            statesStatus === "rejected" ? (
              <p className={styles.err}>
                {statesErr}
                &nbsp; <Retry retry={retry} />
              </p>
            ) : null
          ) : citiesStatus === "rejected" ? (
            <p className={styles.err}>
              {citiesErr}
              &nbsp; <Retry retry={retry} />
            </p>
          ) : null}
        </div>

        <section className={styles.results}>
          {/* FADEYI HUB SECTION */}

          {/* {isFadeyi ? (
            <>
              {cityHubsStatus === "resolved" && data.length ? (
                <>
                  <MapItem showAddress={false} hub={cityHubs[0]} />

                  <div className={styles.center}>
                    <p> Cargo Head Office</p>

                    <p> 52C, Ikorodu Road, Fadeyi, Lagos State.</p>

                    <p>08101312485, 08142552445, 08063261919, 07034030158 .</p>

                    <p> cargoex@abctransport.com</p>
                  </div>
                </>
              ) : null}

              {cityHubsStatus === "pending" ? <p>Loading...</p> : null}

              {cityHubsStatus === "rejected" ? (
                <p className={styles.err}>
                  {cityHubsErr}
                  &nbsp; <Retry retry={retry} />
                </p>
              ) : null}
            </>
          ) : null} */}

          {isState ? (
            <>
              {stateHubsStatus === "pending" ? <p>Loading...</p> : null}

              {stateHubsStatus === "resolved" && data.length
                ? data.map((hub, i) => (
                    <MapItem key={`${i}-${hub.id}`} hub={hub} />
                  ))
                : null}

              {stateHubsStatus === "resolved" && !data.length ? (
                <h4>No branches available for this region</h4>
              ) : null}

              {stateHubsStatus === "rejected" ? (
                <p className={styles.err}>
                  {stateHubsErr}
                  &nbsp; <Retry retry={retry} />
                </p>
              ) : null}
            </>
          ) : null}

          {!isState ? (
            <>
              {cityHubsStatus === "pending" ? <p>Loading...</p> : null}

              {cityHubsStatus === "resolved" && data.length
                ? data.map((hub) => <MapItem key={hub.id} hub={hub} />)
                : null}

              {cityHubsStatus === "resolved" && !data.length ? (
                <h4>No branches available for this region</h4>
              ) : null}

              {cityHubsStatus === "rejected" ? (
                <p className={styles.err}>
                  {cityHubsErr}
                  &nbsp; <Retry retry={retry} />
                </p>
              ) : null}
            </>
          ) : null}

          {/* {!isFadeyi ? (
            <>
              {isState ? (
                <>
                  {stateHubsStatus === "pending" ? <p>Loading...</p> : null}

                  {stateHubsStatus === "resolved" && data.length
                    ? data.map((hub, i) => (
                        <MapItem key={`${i}-${hub.id}`} hub={hub} />
                      ))
                    : null}

                  {stateHubsStatus === "resolved" && !data.length ? (
                    <h4>No branches available for this region</h4>
                  ) : null}

                  {stateHubsStatus === "rejected" ? (
                    <p className={styles.err}>
                      {stateHubsErr}
                      &nbsp; <Retry retry={retry} />
                    </p>
                  ) : null}
                </>
              ) : null}

              {!isState ? (
                <>
                  {cityHubsStatus === "pending" ? <p>Loading...</p> : null}

                  {cityHubsStatus === "resolved" && data.length
                    ? data.map((hub) => <MapItem key={hub.id} hub={hub} />)
                    : null}

                  {cityHubsStatus === "resolved" && !data.length ? (
                    <h4>No branches available for this region</h4>
                  ) : null}

                  {cityHubsStatus === "rejected" ? (
                    <p className={styles.err}>
                      {cityHubsErr}
                      &nbsp; <Retry retry={retry} />
                    </p>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null} */}
        </section>
      </Container>
    </>
  );
};

export default Hubs;
