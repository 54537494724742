import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import styles from "./DateFromTo.module.scss";

const DateFromTo = ({
  onStartDateChange,
  onEndDateChange,
  go,
  btnText = "Go",
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.dropDown}>
        <DayPickerInput
          placeholder="Date From"
          onDayChange={(date) => onStartDateChange(date)}
        />
      </div>
      <div className={styles.dropDown}>
        <DayPickerInput
          placeholder="Date To"
          onDayChange={(date) => onEndDateChange(date)}
        />
      </div>

      <button onClick={go} className={styles.go}>
        {btnText}
      </button>
    </div>
  );
};

export { DateFromTo };
