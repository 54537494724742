// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__UInDe {\n  margin: auto;\n  max-width: 1500px;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,iBAAA;AAAJ","sourcesContent":[".container\n{\n    margin:auto;\n    max-width:1500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__UInDe"
};
export default ___CSS_LOADER_EXPORT___;
