import React from "react";
import {
  Container,
  Header,
  TrackOrderHome,
  OurServices,
  Stats,
  LinedText,
  //Subscribe,
  Testimonial,
} from "@components";

import styles from "./Home.module.scss";

import google_play from "@images/google_play.png";
import app_store from "@images/app_store.png";
import insurance from "@images/git_insur.png";
import reliable from "@images/reliable.png";
import support from "@images/support.png";

const why = [
  {
    pic: reliable,
    text: "RELIABILITY AND PUNCTUALITY",
    class: "reliable",
  },
  {
    pic: insurance,
    text: "GIT insurance",
    class: "insurance",
  },
  {
    pic: support,
    text: "CUSTOMER SUPPORT",
    class: "support",
  },
];

const Home = () => {
  return (
    <>
      <Container>
        <section id="track" className={styles.hero}>
          <div className={styles.content}>
            <Header className={styles.header}>
              Serving All Your Logistics Needs
            </Header>

            <p className={styles.text}>
              E-commerce.&nbsp;&nbsp; Tracking.&nbsp;&nbsp; Seemless Delivery.
            </p>

            <TrackOrderHome section="#section1" />
          </div>
          <aside className={styles.illust}></aside>
        </section>
      </Container>

      <OurServices />

      <Stats />

      <Container className={styles.appwrap}>
        <section id="section1" className={styles.app}>
          <div className={styles.text}>
            <Header className={styles.heading}>
              Deliver Cargos Easily With Our New Mobile App
            </Header>

            <p>
              Enjoy the best experience for your logistics needs from the
              comfort of your mobile phone. Click the links below to download
              now
            </p>

            {/* <h5>
                            COMING SOON
                        </h5> */}

            <div className={styles.stores}>
              <a
                href="https://play.google.com/store/apps/details?id=com.fusion.abccargoxpress&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={google_play} alt="google play store icon" />
              </a>

              <a
                href="https://apps.apple.com/ng/app/abccargoxpress/id1661738819"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={app_store} alt="app store icon" />
              </a>
            </div>
          </div>
        </section>
      </Container>

      <Container className={styles.why}>
        <aside>
          <LinedText>Why</LinedText>
          <LinedText>Choose</LinedText>
          <LinedText>Us</LinedText>
        </aside>

        <section className={styles.pics}>
          {why.map((it) => (
            <article key={it.text} className={styles[it.class]}>
              <p>{it.text}</p>
              <div className={styles.img}>
                <img src={it.pic} alt={it.text} />
              </div>
            </article>
          ))}
        </section>
      </Container>

      <Testimonial />
      {/* 
            <Subscribe /> */}
    </>
  );
};

export default Home;
