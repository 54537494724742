import React from 'react';

import 
{
    Container,
    Header,
    Stats,
    Subscribe,
    ButtonLink
} from "@components";

import styles from './Services.module.scss';

import { useSelector } from "react-redux";


import international_mob from '@images/international-mob.png';
import interstate_mob from '@images/interstate-mob.png';
import courier_mob from '@images/courier_mob.png';
import lmd from '@images/lmd.png';
import dispatch from '@images/dispatch.png';
import household from '@images/household.png';

const items =
    [
        {
            img: interstate_mob,
            title: "Nationwide Delivery",
            text: "We offer Nationwide domestic deliveries to most major cities within Nigeria no matter the size as this service allows for items of various sizes, shapes and weight to be delivered across the nation with the required express handling."
        },
        {
            img: international_mob,
            title: "International Delivery",
            text: "We operate express delivery to locations within West Africadestinations (including Cotonou, Lome, and Accra) in the West Coast either charter or consolidated cargo delivery services. Our expertise on border formalities and customs handling is unequalled."
        },
        {
            img: lmd,
            title: "Last Mile Delivery",
            text: "Fast and efficient same day shipment pick-up and delivery."
        },
        {
            img: courier_mob,
            title: "Charter Service",
            text: "Our fleet of Vans and trucks in sizes 3.5 tons, 10 tons, 14 tons, 22 tons and 30 tons are available for hire. Maintained by best hands in the industry, coupled with customer service oriented support, we offer the best service the haulage industry can boast of."
        },
        {
            img: dispatch,
            title: "Dedicated Dispatch",
            text: "Based on your companies need we can dedicated a bike or a fleet of bikes to serve you. o Restaurants o E-commerce vendors o Corporates"
        },
        {
            img: household,
            title: "Household Moving",
            text: "With this service we make relocation of home or office simple and hassle free. We can pick up from your location and move all items to your new location with the highest professional standards."
        }
    ];


const Services = () =>
{

    const { user } = useSelector( state => state.auth );


    return (
        <>
            <Header center className={ styles.header }>
                Our services
            </Header>




            <Container className={ styles.wrapper }>
                {
                    items.map( it => (
                        <article
                            className={ styles.card }
                            key={ it.title }>

                            <div className={ styles.img }>
                                <img src={ it.img } alt={ it.title } />
                            </div>

                            <Header className={ styles.title }>
                                { it.title }
                            </Header>

                            <p>
                                { it.text }
                            </p>

                            <ButtonLink
                                path={ user?.userName ? "/dashboard" : "/login" }
                                className={ styles.click }>
                                Order Now
                            </ButtonLink>

                        </article>
                    ) )
                }
            </Container>



            <Stats />


            <Subscribe />
        </>
    );
};

export default Services;
