import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import 
{
    LinedText,
    Container,
    ButtonLink
} from '@components';

import styles from './OurServices.module.scss';

import courier from "@images/courier_services.png";
import intDel from "@images/international_delivery.png";


const SPEED = 3500;

const items =
    [
        {
            bg: courier,
            text: "COURIER SERVICES"
        },
        {
            bg: intDel,
            text: "INTERSTATE DELIVERY"
        },
        {
            bg: intDel,
            text: "INTERNATIONAL DELIVERY"
        }
    ];


const OurServices = () => 
{
    const [ isMounted, setIsMounted ] = useState( false );
    const [ activeIndex, setActiveIndex ] = useState( 0 );
    const ref = useRef( null );

    const play = useCallback(
        () =>
        {
            if ( isMounted )
            {
                ref.current = setTimeout( () =>
                {
                    const val = ( activeIndex + 1 ) % items.length;
                    setActiveIndex( val );
                }, SPEED );
            }

        },
        [ activeIndex, isMounted ],
    );

    const pause = () =>
    {
        if ( ref.current )
        {
            clearTimeout( ref.current );
        }

    };

    const handleClick = ( e ) =>
    {
        pause();
        ref.current = null;
        const index = +e.target.dataset.index || 0;
        setActiveIndex( index );
    };


    useEffect( () =>
    {
        setIsMounted( true );

        return () => setIsMounted( false );
    }, [] );

    useEffect( () =>
    {
        play();
        return () => clearTimeout( ref.current );
    }, [ play ] );



    return (
        <Container className={ styles.wrapper }>

            <LinedText>Our Services</LinedText>

            <br />
            <br />

            <section className={ styles.carousel }>

                <div className={ styles.text }>

                    {
                        items.map( ( it, i ) => (
                            <button
                                data-index={ i }
                                className={ classNames(
                                    {
                                        [ styles.active ]: i === activeIndex
                                    } ) }
                                onClick={ handleClick }
                                key={ it.text }>
                                { it.text }
                            </button>
                        ) )
                    }

                </div>

                <div className={ styles.slider }>

                    <aside>


                        {
                            items.map( ( it, i ) => (
                                <img
                                    key={ it.text }
                                    className={ classNames(
                                        {
                                            [ styles.active ]: i === activeIndex
                                        }
                                    ) }
                                    src={ it.bg }
                                    alt={ it.text } />
                            ) )
                        }
                    </aside>

                    <ButtonLink path="/login" className={ styles.btn }>
                        Login
                    </ButtonLink>

                </div>

            </section>

        </Container>
    );
};

export
{
    OurServices
};
