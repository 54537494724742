import { transactionsConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import {
  dispatcher,
  // getLastDay,
  // getFirstDay,
  sortDateDesc,
  formatDate,
} from "../../utils/helpers";
import { addDays } from "date-fns";

const getCustomerTransactions =
  (dateFrom = "", dateTo = "", customerId = "", isDb = false) =>
  async (dispatch, getState) => {
    const dF = formatDate(addDays(new Date(), -20));
    const dT = formatDate(new Date());

    const body = {
      dateFrom: dateFrom || dF,
      dateTo: dateTo || dT,
      customerId: customerId || getState().auth.user.id,
    };

    dispatch(dispatcher(transactionsConstants.FETCH_USER_TRANS_REQUEST));

    const data = await makeRequest({
      path: "Transactions/ListByCustomer",
      method: "POST",
      body,
    });

    if (data.error) {
      dispatch(
        dispatcher(transactionsConstants.FETCH_USER_TRANS_FAILURE, data.error)
      );
    } else {
      const res = JSON.parse(data.data);
      dispatch(
        dispatcher(
          transactionsConstants.FETCH_USER_TRANS_SUCCESS,
          sortDateDesc(res, "createdAt")
        )
      );

      if (!isDb && !getState().trans.transactionDb.length) {
        dispatch(
          dispatcher(
            transactionsConstants.SET_DB_TRANS,
            sortDateDesc(res, "createdAt").slice(0, 10)
          )
        );
      }
    }
  };

const getCustomerTransactions2 = (customerId) => async (dispatch, getState) => {
  const dF = formatDate(addDays(new Date(), -20));
  const dT = formatDate(new Date());

  const body = {
    dateFrom: dF,
    dateTo: dT,
    customerId: customerId || getState().auth.user.id,
  };

  dispatch(dispatcher(transactionsConstants.FETCH_USER_TRANS_REQUEST));

  const data = await makeRequest({
    path: "Transactions/ListByCustomer",
    method: "POST",
    body,
  });

  if (data.error) {
    dispatch(
      dispatcher(transactionsConstants.FETCH_USER_TRANS_FAILURE, data.error)
    );
  } else {
    const res = JSON.parse(data.data);
    const sorted = sortDateDesc(res, "createdAt");

    dispatch(
      dispatcher(transactionsConstants.FETCH_USER_TRANS_SUCCESS, sorted)
    );

    dispatch(
      dispatcher(transactionsConstants.SET_DB_TRANS, sorted.slice(0, 10))
    );
  }
};

export const transactionsActions = {
  getCustomerTransactions,
  getCustomerTransactions2,
};
