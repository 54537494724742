import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { FaBell } from "react-icons/fa";

import styles from './DashboardHeader.module.scss';
import classNames from 'classnames';


const DashboardHeader = (
    {
        isHome,
        isWallet,
        pageTitle,
        useBorder = true
    }
) =>
{
    const { userName } = useSelector( state => state.user );

    return (
        <div className={ classNames( styles.container,
            {
                [ styles.border ]: useBorder
            } ) }>
            <div className={ styles.left }>
                {
                    isHome ?
                        <p className={ styles.welcome }>
                            Hello { userName }
                            <br />
                            what would you like to do today?
                        </p> :
                        <p className={ styles.title }>
                            { pageTitle }
                        </p>
                }
            </div>

            <div className={ styles.right }>
                <button>
                    <FaBell />
                </button>
                {
                    isHome ? (
                        <NavLink className={ styles.edit } to="/dashboard/your-profile" >
                            { userName }
                        </NavLink>
                    ) : null
                }

                {/* {
                    isWallet ? null : (
                        <NavLink className={ styles.wallet } to="/dashboard/top-wallet" >
                            Top-up Wallet
                        </NavLink> )
                } */}

            </div>
        </div>
    );
};

export { DashboardHeader };
