import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import { useJsApiLoader } from '@react-google-maps/api';
import 
{
    Container,
    Header,
    Inputs,
    FormContainer,
    SelectSearchDropDown,
    DistanceMatrixCalculator,
    ButtonLink
} from "@components";

import { quoteActions, cityStateActions } from "@redux/actions";
import { schema, initValues, item } from "./schema";
import { makeRequest, formatCurrency } from "@utils";
import classes from './GetQuote.module.scss';
import styles from '../Page.module.scss';


const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const transformOptions = options =>
{
    return options.map( o => (
        {
            ...o,
            value: o.id
        }
    ) );
};

const transformHubs = options =>
{
    return options.filter( o => o.isActive ).map( o => (
        {
            ...o,
            label: o.name,
            value: `${ o.description }-----${ o.id }`
        }
    ) );
};

const calculateTotal = items =>
{
    const totalAmount = items.reduce( ( sum, it ) => 
    {
        sum = sum + ( +it.amount );
        return sum;
    }, 0 );
    return totalAmount;
};

const isValidItem = ( row ) =>
{
    if ( !row )
    {
        return false;
    }

    const item = { ...row };
    delete item?.amount;
    delete item?.error;

    let valid = true;

    for ( const prop in item ) 
    {
        valid = valid && item[ prop ];
    }

    return valid;

};



const libraries = [ "places" ];



const GetQuote = () =>
{
    const dispatch = useDispatch();
    const { isLoaded, loadError } = useJsApiLoader( {
        id: "__google-map-script",
        googleMapsApiKey: key,
        libraries
    } );
    const [ departureState, setDepartureState ] = useState( "" );
    const [ arrivalState, setArrivalState ] = useState( "" );
    const [ totalKM, setTotalKM ] = useState( 0 );
    const [ totalKMErr, setTotalKMErr ] = useState( null );

    const {
        register,
        control,
        watch,
        formState: { errors },
        setValue,
        getValues } = useForm( {
            resolver: yupResolver( schema ),
            defaultValues: initValues
        } );

    const { fields, append, remove } = useFieldArray( {
        name: "items",
        control,
    } );

    const watchFieldArray = watch( "items" );
    const watchFields = watch( [
        "deliveryTypeId",
        "departureHubId",
        "destinationHubId",
        "serviceTypeId",
        "departureHubAdd",
        "destinationHubAdd",
        "pickupLocation"
    ] );

    const dId = watchFields[ 0 ];


    const clearFields = () =>
    {

        if ( totalKM )
        {
            setTotalKM( 0 );
        }

        if ( totalKMErr )
        {
            setTotalKMErr( null );
        }

        if ( watchFields[ 1 ] )
        {
            setValue( "departureHubId", "" );
        }

        if ( watchFields[ 2 ] )
        {
            setValue( "destinationHubAdd", "" );
            setValue( "destinationHubId", "" );;
        }

        if ( watchFields[ 6 ] )
        {
            setValue( "pickupLocation", "" );
        }
    };

    const controlledFields = fields.map( ( field, index ) =>
    {
        return {
            ...field,
            ...watchFieldArray[ index ]
        };
    } );

    const {
        arrivalHubs,
        departureHubs,
        arrivalStatus,
        departureStatus,
        arrivalErr,
        departureErr,
        serviceTypes,
        serviceTypesErr,
        serviceTypesStatus,
        deliveryTypes,
        deliveryTypesErr,
        deliveryTypesStatus,
        itemTypes,
        itemTypesErr,
        itemTypesStatus,
        shipmentTypes,
        shipmentTypesErr,
        shipmentTypesStatus,
    } = useSelector( state => state.quoteHubs );

    const { user } = useSelector( state => state );

    const {
        states,
        statesStatus,
        statesErr,
    } = useSelector( state => state.cityState );


    const {
        getHubs,
        getServiceTypes,
        getDeliveryTypes,
        getItemTypes,
        getShipmentTypes,
    } = bindActionCreators( quoteActions, dispatch );

    const { getStates } = bindActionCreators( cityStateActions, dispatch );


    const getQuote = async ( item, index ) =>
    {
        const form = getValues();

        const body =
        {
            taxId: "VAT",
            insuranceId: "Insurance_2",
            packagingCostId: "ABC_test2",
            totalKilometers: watchFields[ 0 ] === "HD" ? totalKM : undefined,
            deliveryTypeId: form.deliveryTypeId,
            customerTypeId: user?.customerTypeId || "Regular",
            departureHubId: form.departureHubId,
            destinationHubId: form.destinationHubId,
            serviceTypeId: form.serviceTypeId,
            amount: undefined,
            error: undefined,
            itemTypeId: item?.itemTypeId ? item.itemTypeId : "",
            declared: +item?.declared ? +item.declared : 0,
            quantity: +item?.quantity ? +item.quantity : 0,
            weight: +item?.weight ? +item.weight : 0,
            shipmentTypeId: item?.shipmentTypeId ? item.shipmentTypeId : "",

        };
        setValue( `items.${ index }.error`, "" );
        setValue( `items.${ index }.amount`, "" );

        const data = await makeRequest( {
            path: `/Orders/GetQuote`,
            method: "POST",
            body
        } );

        if ( data.error )
        {
            setValue( `items.${ index }.error`, data.error );
        }
        else
        {
            const res = JSON.parse( data.data );
            setValue( `items.${ index }.amount`, +res.amount );
        }

    };

    const check = async () => 
    {
        const items = controlledFields;

        for ( let index = 0; index < items.length; index++ ) 
        {
            const item = items[ index ];
            const { deliveryTypeId, departureHubId, destinationHubId, pickupLocation,
                serviceTypeId } = getValues();
            const valid = isValidItem( item ) && destinationHubId &&
                serviceTypeId && deliveryTypeId && departureHubId && ( dId === "HD" ? pickupLocation : true );

            if ( valid )
            {
                getQuote( item, index );
            }

        }

    };

    const onChange = ( e, index ) =>
    {
        const { name, value } = e.target;

        let item =
        {
            ...controlledFields[ index ],
            [ name.split( "." )[ 2 ] ]: value
        };
        const { deliveryTypeId, departureHubId, destinationHubId, pickupLocation,
            serviceTypeId } = getValues();
        const valid = isValidItem( item ) && destinationHubId &&
            serviceTypeId && deliveryTypeId && departureHubId && ( dId === "HD" ? pickupLocation : true );

        if ( valid )
        {
            getQuote( item, index );
        }

    };

    const onChangeField = async ( e ) =>
    {
        const { name, value } = e.target;
        const item = controlledFields[ 0 ];

        const form = getValues();

        for ( let i = 0; i < controlledFields.length; i++ ) 
        {
            setValue( `items.${ i }.error`, "" );
            setValue( `items.${ i }.amount`, "" );
        }


        let { deliveryTypeId, departureHubId, destinationHubId, pickupLocation,
            serviceTypeId } = getValues();
        const valid = isValidItem( item ) && value && destinationHubId &&
            serviceTypeId && deliveryTypeId && departureHubId && ( dId === "HD" ? pickupLocation : true );

        if ( valid )
        {

            const body =
            {
                taxId: "VAT",
                insuranceId: "Insurance_2",
                packagingCostId: "ABC_test2",
                totalKilometers: watchFields[ 0 ] === "HD" ? totalKM : undefined,
                deliveryTypeId: form.deliveryTypeId,
                customerTypeId: user?.customerTypeId || "Regular",
                departureHubId: form.departureHubId,
                destinationHubId: form.destinationHubId,
                serviceTypeId: form.serviceTypeId,
                amount: undefined,
                error: undefined,
                itemTypeId: item?.itemTypeId ? item.itemTypeId : "",
                declared: +item?.declared ? +item.declared : 0,
                quantity: +item?.quantity ? +item.quantity : 0,
                weight: +item?.weight ? +item.weight : 0,
                shipmentTypeId: item?.shipmentTypeId ? item.shipmentTypeId : "",
                [ name ]: value

            };


            const data = await makeRequest( {
                path: `/Orders/GetQuote`,
                method: "POST",
                body
            } );

            if ( data.error )
            {
                for ( let i = 0; i < controlledFields.length; i++ ) 
                {
                    setValue( `items.${ i }.error`, data.error );
                }

            }
            else
            {
                const res = JSON.parse( data.data );

                for ( let i = 0; i < controlledFields.length; i++ ) 
                {
                    setValue( `items.${ i }.amount`, +res.amount );

                }
            }
        }

    };

    const onSelect = ( type, value ) =>
    {
        if ( type === "dep" )
        {
            setDepartureState( value );
        }
        else
        {
            setArrivalState( value );
        }

        getHubs( value, type );
        check();

    };

    const onSelectHub = ( type, value ) =>
    {
        const [ address, id ] = value.split( "-----" );

        if ( type === "dep" )
        {
            setValue( "departureHubAdd", address );
            setValue( 'departureHubId', id );
        }
        else
        {
            setValue( "destinationHubAdd", address );
            setValue( "destinationHubId", id );
        }

        check();

    };

    useEffect( () =>
    {
        if ( statesStatus === "idle" )
        {
            getStates();
        }
        if ( shipmentTypesStatus === "idle" )
        {
            getShipmentTypes();
        }
        if ( deliveryTypesStatus === "idle" )
        {
            getDeliveryTypes();
        }
        if ( serviceTypesStatus === "idle" )
        {
            getServiceTypes();
        }
        if ( itemTypesStatus === "idle" )
        {
            getItemTypes();
        }
    }, [ getStates,
        getServiceTypes,
        getDeliveryTypes,
        getItemTypes,
        getShipmentTypes,
        serviceTypesStatus,
        deliveryTypesStatus,
        itemTypesStatus,
        statesStatus,
        shipmentTypesStatus ] );

    const retry = () =>
    {
        if ( statesStatus === "rejected" )
        {
            getStates();
        }
        if ( shipmentTypesStatus === "rejected" )
        {
            getShipmentTypes();
        }
        if ( deliveryTypesStatus === "rejected" )
        {
            getDeliveryTypes();
        }
        if ( serviceTypesStatus === "rejected" )
        {
            getServiceTypes();
        }
        if ( itemTypesStatus === "rejected" )
        {
            getItemTypes();
        }
    };

    const errorLoadingAnyDropDown = statesStatus === "rejected" || shipmentTypesStatus === "rejected" || deliveryTypesStatus === "rejected" || serviceTypesStatus === "rejected" || itemTypesStatus === "rejected";



    return (
        <>
            <Container>

                <br />

                <Header center>Get A Quote </Header>

                <FormContainer
                    message={ `Please fill the form below to get a quote for delivery` }
                    formClass={ classes.formClass }
                >

                    {
                        errorLoadingAnyDropDown ?
                            <ButtonLink className={ classes.retry } onClick={ retry }>
                                Failed to load some options. Click to retry.
                            </ButtonLink> : null
                    }

                    <br />

                    <div className={ classes.Err }>

                        {

                            statesStatus === "rejected" ? <p>
                                States error : { statesErr }</p> : null
                        }


                    </div>

                    <div className={ classes.Err }>

                        {

                            departureStatus === "rejected" ? <p>
                                Departure Hub error :{ departureErr }</p> : null
                        }


                    </div>

                    <div className={ classes[ "form-row" ] }>

                        <Inputs
                            inputProps={
                                {
                                    ...register( "deliveryTypeId",
                                        {
                                            onChange: ( e ) =>
                                            {
                                                onChangeField( e );
                                                clearFields();

                                            }
                                        } ),
                                    name: "deliveryTypeId",
                                    fade: true,
                                    options: deliveryTypes.filter( d => d.id !== "HD" )

                                }
                            }
                            type="select"
                            label="Delivery Type"
                            labelClass={ styles.label }
                            error={ ( errors.deliveryTypeId && errors.deliveryTypeId?.message ) || ( deliveryTypesStatus === "rejected" && deliveryTypesErr ) }
                        />


                        <Inputs
                            inputProps={
                                {
                                    ...register( "serviceTypeId",
                                        {
                                            onChange: ( e ) =>
                                            {
                                                onChangeField( e );

                                                if ( departureState )
                                                {
                                                    setDepartureState( "" );
                                                }
                                                if ( arrivalState )
                                                {
                                                    setArrivalState( "" );
                                                }

                                                clearFields();

                                            }
                                        } ),
                                    name: "serviceTypeId",
                                    fade: true,
                                    options: serviceTypes

                                }
                            }
                            type="select"
                            label="Service Type"
                            labelClass={ styles.label }
                            error={ ( errors.serviceTypeId && errors.serviceTypeId?.message ) || ( serviceTypesStatus === "rejected" && serviceTypesErr ) }
                        />

                        <SelectSearchDropDown
                            label={ <h4 className={ classes.dropLabel }>Departure state</h4> }
                            options={
                                statesStatus === "resolved" ? transformOptions( states ) : []
                            }
                            search={ true }
                            name={ "Pick" }
                            value={ departureState }
                            disabled={ ( statesStatus !== "resolved" && !!states.length ) || statesStatus === "rejected" || statesStatus === "pending" }
                            onChange={ ( value ) => onSelect( "dep", value ) }
                            placeholder={ statesStatus === "pending" ? "Loading..." : "Choose departure state..." } />




                    </div>

                    <br />

                    <div className={ classes.Err }>

                        {

                            arrivalStatus === "rejected" ? <p>
                                Arrival Hub error { arrivalErr }</p> : null
                        }


                    </div>

                    <div className={ classes[ "form-row" ] }>



                        <SelectSearchDropDown
                            label={ <h4 className={ classes.dropLabel }>Departure hub</h4> }

                            options={
                                ( departureStatus === "resolved" && !!departureHubs.length ) ? transformHubs( departureHubs ) : []
                            }
                            search={ true }
                            name={ "Pick" }
                            value={ `${ watchFields[ 4 ] }-----${ watchFields[ 1 ] }` }

                            disabled={ ( departureStatus === "resolved" && !departureHubs.length ) || departureStatus === "rejected" || departureStatus === "pending" }
                            onChange={ ( value ) => onSelectHub( "dep", value ) }
                            placeholder={ departureStatus === "pending" ? "Loading..." : "Choose departure hub..." } />

                        <SelectSearchDropDown
                            label={ <h4 className={ classes.dropLabel }>Arrival state</h4> }

                            options={
                                statesStatus === "resolved" ? transformOptions( states ) : []
                            }
                            search={ true }
                            name={ "Pick" }
                            value={ arrivalState }
                            disabled={ ( statesStatus !== "resolved" && !!states.length ) || statesStatus === "rejected" || statesStatus === "pending" }
                            onChange={ ( value ) => onSelect( "arr", value ) }
                            placeholder={ statesStatus === "pending" ? "Loading..." : "Choose arrival state..." } />

                        <SelectSearchDropDown
                            label={ <h4 className={ classes.dropLabel }>Arrival hub</h4> }

                            options={
                                ( arrivalStatus === "resolved" && !!arrivalHubs.length ) ? transformHubs( arrivalHubs ) : []
                            }
                            search={ true }
                            name={ "Pick" }
                            value={ `${ watchFields[ 5 ] }-----${ watchFields[ 2 ] }` }
                            disabled={ ( arrivalStatus === "resolved" && !arrivalHubs.length ) || arrivalStatus === "rejected" || arrivalStatus === "pending" }
                            onChange={ ( value ) => onSelectHub( "arr", value ) }
                            placeholder={ arrivalStatus === "pending" ? "Loading..." : "Choose arrival hub..." } />

                    </div>

                    {
                        dId === "HD" ?
                            <Inputs
                                inputProps={
                                    {
                                        ...register( "pickupLocation",
                                            {
                                                onChange: onChangeField
                                            } ),
                                        name: `pickupLocation`,

                                    }
                                }
                                label="Delivery Address"
                                labelClass={ styles.label }
                                error={ errors?.[ "pickupLocation" ]?.[ 'message' ] }
                            />
                            : null
                    }

                    {
                        ( loadError || errors?.totalKilometers?.message ) ? <div className={ classes.Err }>

                            <p>Unable to calculate distance</p>

                        </div> : null
                    }

                    {
                        ( isLoaded && watchFields[ 1 ] && watchFields[ 2 ] && watchFields[ 0 ] === "HD" && watchFields[ 6 ] && !( loadError ) ) ?
                            (
                                <DistanceMatrixCalculator
                                    options={ {
                                        destinations: [ dId === "HD" ? watchFields[ 6 ] : watchFields[ 5 ] ],
                                        origins: [ watchFields[ 4 ] ],
                                        travelMode: "DRIVING",
                                    } }
                                    callback={ ( response ) =>
                                    {
                                        const val = response?.rows[ 0 ]?.elements[ 0 ]?.distance?.value / 1000;

                                        if ( isNaN( val ) )
                                        {
                                            setTotalKM( 0 );
                                            setTotalKMErr( "Unable to calculate distance. Please choose another location" );
                                        }
                                        else
                                        {
                                            setTotalKMErr( null );

                                            setTotalKM( val );
                                        }

                                    } }
                                />
                            )
                            : null
                    }

                    <h3 className={ classes.items }>Total KM = { totalKM }km</h3>

                    { totalKMErr ? <div className={ classes.Err }>
                        <small>{ totalKMErr }</small>
                    </div> : null }

                    <br />

                    <h3 className={ classes.items }>Items</h3>
                    <hr />

                    <br />

                    { fields.length < 1 ? <div className={ classes.Err }>
                        <small>Please add at least one item</small>
                    </div> : null }

                    {
                        controlledFields.map( ( item, index ) => (
                            <div
                                key={ item.id }>
                                <div
                                    className={ classes[ "item-row" ] }>

                                    <Inputs
                                        label="Shipment Type"
                                        type="select"
                                        inputProps=
                                        {
                                            {
                                                ...register( `items.${ index }.shipmentTypeId`,
                                                    {
                                                        onChange: ( e ) => onChange( e, index )
                                                    } ),
                                                name: `items.${ index }.shipmentTypeId`,
                                                fade: true,
                                                options: shipmentTypes
                                            }
                                        }
                                        error={ ( errors?.[ "items" ]?.[ index ]?.[ 'shipmentTypeId' ]?.[ 'message' ] ) || ( shipmentTypesStatus === "rejected" && shipmentTypesErr ) }
                                        labelClass={ styles.label }

                                    />


                                    <Inputs
                                        label="Item Type"
                                        type="select"
                                        inputProps={
                                            {
                                                ...register( `items.${ index }.itemTypeId`,
                                                    {
                                                        onChange: ( e ) => onChange( e, index )
                                                    } ),
                                                name: `items.${ index }.itemTypeId`
                                                ,
                                                fade: true,
                                                options: itemTypes.filter( it => it.name.includes( controlledFields[ index ].shipmentTypeId ) )

                                            }
                                        }
                                        error={ ( errors?.[ "items" ]?.[ index ]?.[ 'itemTypeId' ]?.[ 'message' ] ) || ( itemTypesStatus === "rejected" && itemTypesErr ) }
                                        labelClass={ styles.label }

                                    />



                                    <Inputs
                                        inputProps={
                                            {
                                                ...register( `items.${ index }.declared`,
                                                    {
                                                        onChange: ( e ) => onChange( e, index )
                                                    } ),
                                                name: `items.${ index }.declared`,

                                            }
                                        }
                                        wrapperClass={ classes.num2 }
                                        type="number"
                                        label="Declared value"
                                        labelClass={ styles.label }
                                        error={ errors?.[ "items" ]?.[ index ]?.[ 'declared' ]?.[ 'message' ] }
                                    />

                                    <Inputs
                                        inputProps={
                                            {
                                                ...register( `items.${ index }.weight`,
                                                    {
                                                        onChange: ( e ) => onChange( e, index )
                                                    } ),
                                                name: `items.${ index }.weight`,

                                            }
                                        }
                                        wrapperClass={ classes.num }
                                        type="number"
                                        label="Weight (in kg)"
                                        labelClass={ styles.label }
                                        error={ errors?.[ "items" ]?.[ index ]?.[ 'weight' ]?.[ 'message' ] }
                                    />

                                    <Inputs
                                        wrapperClass={ classes.num }
                                        inputProps={
                                            {
                                                ...register( `items.${ index }.quantity`,
                                                    {
                                                        onChange: ( e ) => onChange( e, index )
                                                    } ),
                                                name: `items.${ index }.quantity`,

                                            }
                                        }
                                        type="number"
                                        label="Quantity"
                                        labelClass={ styles.label }
                                        error={ errors?.[ "items" ]?.[ index ]?.[ 'quantity' ]?.[ 'message' ] }
                                    />
                                    {/* <button
                                        type="button"
                                        className={ classes.get }
                                        disabled={ !isValidItem( item ) || !validWatchFields( watchFields ) }
                                        onClick={ () => getQuote( item, index ) }
                                    >
                                        Get quote
                                    </button> */}

                                    <button
                                        className={ classes.remove } type="button"
                                        onClick={ () => remove( index ) } >
                                        <i className="lar la-times-circle"></i>
                                    </button>
                                </div>
                                {
                                    item.error ? <div className={ classes.Err }>
                                        <small>{ item.error }</small>
                                    </div> : null
                                }
                            </div>
                        ) )
                    }

                    <br />


                    <button
                        type="button"
                        className={ classes.append }
                        onClick={ () => append( { ...item } ) }
                    >
                        ADD
                    </button>

                    <br />

                    <br />

                    <div className={ classes.total }>

                        <h3>Total</h3>

                        <h3>{ formatCurrency( calculateTotal( controlledFields ) ) }</h3>
                    </div>

                    {/* <ButtonLink type="submit" className={ styles.submit }>
                        Get Quote
                    </ButtonLink> */}

                </FormContainer>
            </Container>

        </>
    );
};

export default GetQuote;
