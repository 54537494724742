import React from 'react';
import { useSelector } from "react-redux";

import 
{
    Container,
    Header,
    Stats,
    Subscribe,
    Testimonial,
    ButtonLink
} from "@components";


import who from '@images/who.png';
import courier from "@images/courier_services.png";

import bulk from "@images/icons/bulk.svg";
import cheap from "@images/icons/Cheap.svg";
import COD from "@images/icons/COD.svg";
import fast from "@images/icons/fast.svg";
import order from "@images/icons/order.svg";
import self from "@images/icons/self.svg";


import styles from './Business.module.scss';
import classNames from 'classnames';


const items =
    [
        {
            text: "With ABC, deliveries to your clients is made easier, faster and safer with just the click of a button",
            title: "Logistic solutions for enterprises of all sizes",
            img: who
        },
        {
            text: "With ABC, deliveries to your clients is made easier, faster and safer with just the click of a button",
            title: "E-commerce logistics support service",
            img: courier
        },
    ];



const list =
    [
        {
            pos: "top-left",
            icon: self,
            render: () => <Header className={ styles.item }>SELF-SERVICE PORTAL</Header>
        },
        {
            pos: "top-right",
            icon: fast,
            render: () => <Header className={ styles.item }>FASTER DELIVERY</Header>
        },
        {
            pos: "bottom-right",
            icon: cheap,
            render: () => <Header className={ styles.item }>CHEAPER RATES</Header>
        },
        {
            pos: "bottom-left",
            icon: order,
            render: () => <Header className={ styles.item }>EASY ORDER MANAGEMENT</Header>
        },
        {
            pos: "top-right",
            icon: COD,
            render: () => <Header className={ styles.item }>PAYMENT ON DELIVERY FOR CUSTOMERS</Header>
        },
        {
            pos: "bottom-left",
            icon: bulk,
            render: () => <Header className={ styles.item }>BULK ORDER DELIVERY</Header>
        },
    ];


const Business = () =>
{
    const { user } = useSelector( state => state.auth );

    return (
        <>
            <Container className={ styles.wrapper }>

                {
                    items.map( ( it, i ) => (
                        <article key={ i } className={ styles.card }>

                            <section className={ styles.content }>
                                <Header className={ styles.head }>
                                    { it.title }
                                </Header>
                                <p>{ it.text }</p>

                                <ButtonLink
                                    path={ user?.userName ? "/dashboard" : "/login" }
                                    className={ classNames( styles.link, styles.hiddenXs ) }>
                                    Get started
                                </ButtonLink>
                            </section>

                            <div className={ styles.img } >
                                <img src={ it.img } alt={ it.title } />
                            </div>

                            <ButtonLink
                                path={ user?.userName ? "/dashboard" : "/login" }
                                className={ classNames( styles.link, styles.hiddenLg ) }>
                                Get started
                            </ButtonLink>

                        </article>
                    ) )
                }

            </Container>

            <Stats
                isBiz
                containerClass={ styles.containerClass }
                imgWrap={ styles.imgWrap }
                list={ list } />



            <Testimonial />


            <Subscribe />
        </>
    );
};

export default Business;
