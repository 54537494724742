import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";

import { authConstants } from "./constants";

import
{
    auth,
    trans,
    orders,
    user,
    hubs,
    cityState,
    quotes,
}
    from "./reducers";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const appReducer = combineReducers( {
    auth,
    trans,
    orders,
    user,
    hubs,
    quoteHubs: quotes,
    cityState
} );


const rootReducer = ( state, action ) =>
{
    //wipe everything on logout
    //to cater for different user login
    if ( action.type === authConstants.LOGOUT_USER_SUCCESS )
    {
        return appReducer( undefined, action );
    }

    return appReducer( state, action );
};

const store = createStore( rootReducer, /* preloadedState, */ composeEnhancers(
    compose(
        applyMiddleware( thunk )
    ) ) );


export { store };
