import { transactionsConstants } from '../constants';

import 
{
    request,
    failure
} from "../../utils/helpers";

const initialState =
{
    loading: false,
    transactions: [],
    transactionDb: [],
    error: null,
    status: "idle"
};


export function trans ( state = initialState, action )
{
    const { type, payload } = action;

    switch ( type )
    {
        case transactionsConstants.FETCH_USER_TRANS_REQUEST:
            return request( state );

        case transactionsConstants.FETCH_USER_TRANS_FAILURE:
            return failure( state, action.payload );

        case transactionsConstants.FETCH_USER_TRANS_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: payload,
                status: "resolved"
            };


        case transactionsConstants.SET_DB_TRANS:
            return {
                ...state,
                transactionDb: payload
            };


        default:
            return state;
    }
}
