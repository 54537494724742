import React from 'react';
//import { useForm } from "react-hook-form";
//import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers/yup';

import styles from './Subscribe.module.scss';


import 
{
    Container,
    Header,
    ButtonLink,
    //Inputs
} from "@components";


// const schema = yup.object().shape(
//     {
//         email: yup.string().required().email( "Please input a valid email" )
//     }
// ).required();


const Subscribe = ( { message, header } ) => 
{
    // const { register, formState: { errors }, handleSubmit, reset } = useForm( {
    //     resolver: yupResolver( schema )
    // } );


    // const onSubmit = data => 
    // {
    //     console.log( data );
    //     reset( { email: "" } );
    // };

    return (
        <section className={ styles.wrapper }>
            <Container className={ styles.container }>

                {
                    header ? header : <Header className={ styles.heading }>
                        Subscribe to our newsletter
                    </Header>
                }



                {
                    message ? message :
                        <p>
                            Sign up to get first hand information about updates on our services
                        </p>
                }


                {/* <form
                    onSubmit={ handleSubmit( onSubmit ) } >
                    <Inputs
                        inputProps={
                            {
                                ...register( "email" ),
                                name: "email",
                            }
                        }
                        type="email"
                        error={ errors.email && errors.email?.message }
                    />

                </form> */}

                <br />
                <br />



                <ButtonLink
                    isMail
                    path="mailto:cargoex@abctransport.com?subject=Susbscription">
                    Subscribe
                </ButtonLink>
            </Container>
        </section>
    );
};

export { Subscribe };
