import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link";

import { Inputs, ButtonLink } from "@components";

import { makeRequest /*, mapOrderStatus*/ } from "@utils";
import { hubActions } from "@redux/actions";

import styles from "./TrackOrderHome.module.scss";

const hubsLookupTable = (hubs) => {
  return hubs.reduce((acc, hub) => {
    acc[hub.id] = hub.name;
    return acc;
  }, {});
};

const TrackOrderHome = ({ section }) => {
  const dispatch = useDispatch();

  const { status, hubs: hubsList } = useSelector((state) => state.hubs);

  let hubs = hubsLookupTable(hubsList);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    order: null,
    error: null,
    status: "idle",
  });

  const trackOrder = async ({ orderId }) => {
    setState({
      order: null,
      error: null,
      status: "pending",
    });

    const resp = await makeRequest({
      path: `Orders/TrackOrderStatus?orderId=${orderId?.trim().toUpperCase()}`,
    });

    if (resp.error) {
      setState({
        order: null,
        error: resp.error.toString(),
        status: "rejected",
      });
    } else {
      const order = JSON.parse(resp.data);

      setState({
        order: {
          ...order,
          originHubId: hubs[order.originHubId],
          destinationHubId: hubs[order.destinationHubId],
        },
        error: null,
        status: "resolved",
      });
    }
  };

  useEffect(() => {
    if (status === "idle" || status === "rejected") {
      dispatch(hubActions.getHubs());
    }
  }, [dispatch, status]);

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(trackOrder)}>
        <Inputs
          inputProps={{
            ...register("orderId", { required: true }),
            name: "orderId",
            placeholder: "e.g. 000B00",
          }}
          error={
            errors.orderId &&
            "Please enter the waybill 6 character code of the order to track"
          }
        />

        <div className={styles.row}>
          <ButtonLink type="submit">Track Order</ButtonLink>
          {/* 
          <ButtonLink outline path="/quote">
            Get Quote
          </ButtonLink> */}
          <HashLink smooth to={section}>
            <div className={styles.buttonn}>
              <div className={styles.download}>
                <div>
                  {" "}
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V11H2V14H14V11H16V14C16 14.55 15.804 15.021 15.412 15.413C15.02 15.805 14.5493 16.0007 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p> Download The App</p>
              </div>
            </div>
          </HashLink>
        </div>
      </form>

      <br />
      <br />

      {state.status === "rejected" ? (
        <p className={styles.error}>{state.error}</p>
      ) : null}

      {state.status === "pending" ? (
        <p>
          {" "}
          <strong>Fetching waybill status ... </strong>{" "}
        </p>
      ) : null}

      {state.order ? (
        <article>
          {state.status === "resolved" ? (
            <div className={styles.order}>
              <p>
                {" "}
                <strong>Origin Hub </strong> : {state.order.originHubId}
              </p>

              <p>
                {" "}
                <strong>Destination Hub </strong> :{" "}
                {state.order.destinationHubId}
              </p>

              <p>
                {" "}
                <strong>Status </strong> :{state.order.trackingMessage}
              </p>
            </div>
          ) : null}
        </article>
      ) : null}

      <br />
      <br />
    </div>
  );
};

export { TrackOrderHome };
