import React from "react";
import { EmptyIcon } from "../../assets/images";
import styles from "./emptystate.module.scss";

function EmptyState({ text, handleClick, buttonText, showButton }) {
  return (
    <div className={styles.empty} data-testid="empty-state">
      <EmptyIcon />
      <h3>There’s nothing to see here</h3>
      <p>{text}</p>
      {showButton && (
        <button onClick={handleClick} variant="primary">
          {buttonText}
        </button>
      )}
    </div>
  );
}

export { EmptyState };
