import { authConstants } from '../constants';
import 
{
    request,
    failure
} from "../../utils/helpers";


const initialState =
{
    loading: false,
    user: {},
    error: null,
    status: "idle"
};

export function auth ( state = initialState, action )
{
    switch ( action.type )
    {
        case authConstants.LOGIN_USER_FAILURE:
            return failure( state, action.payload );

        case authConstants.LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                status: "resolved"
            };

        case authConstants.LOGIN_USER_REQUEST:
            return request( state );

        case authConstants.LOGOUT_USER_SUCCESS:
            return initialState;

        default:
            return state;
    }
}
