import React, { useLayoutEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { authActions, hubActions, userActions } from '@redux/actions';

import { checkLogin } from "@utils";

function PrivateRoute ( { children, ...props } ) 
{
    const { getUserStatus } = useSelector( state => state.user );
    const { user } = useSelector( state => state.auth );
    const { status } = useSelector( state => state.hubs );

    const dispatch = useDispatch();

    if ( !user?.token )
    {
        const abcUser = localStorage.getItem( 'abc-user' );


        if ( checkLogin() && abcUser )
        {


            const parsed = JSON.parse( abcUser );
            dispatch( authActions.setUser( parsed ) );

            if ( getUserStatus === "idle" )
            {
                dispatch( userActions.getUserById( parsed.id ) );
            }

            if ( status === "idle" )
            {
                dispatch( hubActions.getHubs() );
            }

        }

    }

    useLayoutEffect( () =>
    {
        if ( !user?.token )
        {
            <Navigate to="/login" />;
        }
    }, [ user ] );


    return (
        ( user?.token ) ?
            children
            : <Navigate to="/login" />
    );
}

export { PrivateRoute };
