import { cityStateConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import { dispatcher } from "../../utils/helpers";


const getCities = () => async ( dispatch ) =>
{
    dispatch( dispatcher( cityStateConstants.FETCH_CITIES_REQUEST ) );


    const data = await makeRequest( {
        path: "Cities/ViewAllCities",
        method: "GET",
    } );


    if ( data.error )
    {
        dispatch( dispatcher( cityStateConstants.FETCH_CITIES_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        dispatch( dispatcher( cityStateConstants.FETCH_CITIES_SUCCESS, res ) );
    }
};


const getStates = () => async ( dispatch ) =>
{
    dispatch( dispatcher( cityStateConstants.FETCH_STATES_REQUEST ) );


    const data = await makeRequest( {
        path: "/States/ViewAllStates",
        method: "GET",
    } );


    if ( data.error )
    {
        dispatch( dispatcher( cityStateConstants.FETCH_STATES_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        dispatch( dispatcher( cityStateConstants.FETCH_STATES_SUCCESS, res ) );
    }
};


const setStatus = ( key, value ) => (
    {
        type: cityStateConstants.SET_STATUS,
        payload:
        {
            key,
            value
        }
    }
);


export const cityStateActions =
{
    setStatus,
    getCities,
    getStates
};
