import React from 'react';
import classNames from "classnames";

import 
{
    Container,
    Header
} from '@components';

import styles from './Stats.module.scss';

import clients from "@images/icons/clients.svg";
import locations from "@images/icons/locations.svg";
import transported from "@images/icons/transported.svg";
import vehicles from "@images/icons/vehicles.svg";


const items =
    [
        {
            pos: "top-left",
            icon: clients,
            text: "10,000 + Clients"
        },
        {
            pos: "top-right",
            icon: locations,
            text: "58 + Locations"
        },
        {
            pos: "top-left",
            icon: vehicles,
            text: "172 + Vehicles"
        },
        {
            pos: "top-right",
            icon: transported,
            text: "5,000,000 + Transported"
        },
    ];


const Stats = ( {
    list = items,
    containerClass,
    isBiz,
    imgWrap,
    gridItem
} ) =>
{
    if ( isBiz )
    {
        return (
            <section className={ styles.wrapper }>

                <Container className={ classNames( styles.biz, containerClass ) }>

                    <Header className={ styles.heading }>

                        What your
                        <br />
                        business needs
                    </Header>

                    <div className={ styles.list }>
                        {
                            list.map( ( it, i ) => (
                                <article className={ gridItem } key={ i }>
                                    <div className={ classNames( styles.img,
                                        styles[ it.pos ],
                                        imgWrap ) }>
                                        <img src={ it.icon } alt={ it.text } />

                                    </div>

                                    {
                                        it.render ? it.render() : <p>{ it.text }</p>
                                    }
                                </article>
                            ) )
                        }
                    </div>

                </Container>

            </section>
        );
    }
    return (
        <section className={ styles.wrapper }>

            <Container className={ classNames( styles.container, containerClass ) }>
                {
                    list.map( ( it, i ) => (
                        <article className={ gridItem } key={ i }>
                            <div className={ classNames( styles.img,
                                styles[ it.pos ], imgWrap ) }>
                                <img src={ it.icon } alt={ it.text } />

                            </div>

                            {
                                it.render ? it.render() : <p>{ it.text }</p>
                            }
                        </article>
                    ) )
                }
            </Container>

        </section>
    );
};

export { Stats };
