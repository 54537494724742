import React, { useEffect } from 'react';

const useDebouncedEffect = ( effect, deps, delay ) =>
{
    useEffect( () =>
    {
        const handler = setTimeout( () => effect(), delay );

        return () => clearTimeout( handler );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ...( deps || [] ), delay ] );
};

const DistanceMatrixCalculator = (
    {
        options,
        delay = 800,
        callback
    }
) =>
{

    useDebouncedEffect( () =>
    {
        const { origins, destinations } = options;
        const service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
            {
                origins, destinations,
                travelMode: 'DRIVING',
            }, callback );

    }, [ options, callback ], delay );



    return ( <></> );
};


export { DistanceMatrixCalculator };
