import { quoteConstants } from '../constants';


const initialState =
{
    arrivalHubs: [],
    departureHubs: [],
    arrivalStatus: "idle",
    departureStatus: "idle",
    arrivalErr: null,
    departureErr: null,
    serviceTypes: [],
    serviceTypesErr: null,
    serviceTypesStatus: "idle",
    deliveryTypes: [],
    deliveryTypesErr: null,
    deliveryTypesStatus: "idle",
    itemTypes: [],
    itemTypesErr: null,
    itemTypesStatus: "idle",
    shipmentTypes: [],
    shipmentTypesErr: null,
    shipmentTypesStatus: "idle",
};


export function quotes ( state = initialState, action )
{
    const { type, payload } = action;
    switch ( type )
    {


        case quoteConstants.FETCH_DEP_HUBS_REQUEST:
            return {
                ...state,
                departureErr: null,
                departureStatus: "pending"
            };

        case quoteConstants.FETCH_ARR_HUBS_REQUEST:
            return {
                ...state,
                arrivalErr: null,
                arrivalStatus: "pending"
            };

        case quoteConstants.FETCH_DELIVERY_TYPES_REQUEST:
            return {
                ...state,
                deliveryTypesErr: null,
                deliveryTypesStatus: "pending"
            };

        case quoteConstants.FETCH_SERVICE_TYPES_REQUEST:
            return {
                ...state,
                serviceTypesErr: null,
                serviceTypesStatus: "pending"
            };


        case quoteConstants.FETCH_SHIPMENT_TYPES_REQUEST:
            return {
                ...state,
                shipmentTypesErr: null,
                shipmentTypesStatus: "pending"
            };

        case quoteConstants.FETCH_ITEM_TYPES_REQUEST:
            return {
                ...state,
                itemTypesErr: null,
                itemTypesStatus: "pending"
            };

        case quoteConstants.FETCH_DEP_HUBS_FAILURE:
            return {
                ...state,
                departureErr: payload,
                departureStatus: "rejected"
            };

        case quoteConstants.FETCH_ARR_HUBS_FAILURE:
            return {
                ...state,
                arrivalErr: payload,
                arrivalStatus: "rejected"
            };


        case quoteConstants.FETCH_DELIVERY_TYPES_FAILURE:
            return {
                ...state,
                deliveryTypesErr: payload,
                deliveryTypesStatus: "rejected"
            };

        case quoteConstants.FETCH_SERVICE_TYPES_FAILURE:
            return {
                ...state,
                serviceTypesErr: payload,
                serviceTypesStatus: "rejected"
            };


        case quoteConstants.FETCH_SHIPMENT_TYPES_FAILURE:
            return {
                ...state,
                shipmentTypesErr: payload,
                shipmentTypesStatus: "rejected"
            };

        case quoteConstants.FETCH_ITEM_TYPES_FAILURE:
            return {
                ...state,
                itemTypesErr: payload,
                itemTypesStatus: "rejected"
            };


        case quoteConstants.FETCH_DEP_HUBS_SUCCESS:
            return {
                ...state,
                departureErr: null,
                departureStatus: "resolved",
                departureHubs: payload,

            };

        case quoteConstants.FETCH_ARR_HUBS_SUCCESS:
            return {
                ...state,
                arrivalErr: null,
                arrivalStatus: "resolved",
                arrivalHubs: payload,
            };


        case quoteConstants.FETCH_DELIVERY_TYPES_SUCCESS:
            return {
                ...state,
                deliveryTypesErr: null,
                deliveryTypes: payload,
                deliveryTypesStatus: "resolved"
            };

        case quoteConstants.FETCH_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                serviceTypesErr: null,
                serviceTypes: payload,
                serviceTypesStatus: "resolved"
            };


        case quoteConstants.FETCH_SHIPMENT_TYPES_SUCCESS:
            return {
                ...state,
                shipmentTypes: payload,
                shipmentTypesErr: null,
                shipmentTypesStatus: "resolved"
            };

        case quoteConstants.FETCH_ITEM_TYPES_SUCCESS:
            return {
                ...state,
                itemTypes: payload,
                itemTypesErr: null,
                itemTypesStatus: "resolved"
            };

        case quoteConstants.SET_STATUS:
            const { key, value } = payload;
            return {
                ...state,
                [ key ]: value,
            };

        default:
            return state;
    }
}
