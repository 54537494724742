import React from 'react';
import Carousel from "react-multi-carousel";

import 
{
    Container,
    LinedText,
} from "@components";

import "react-multi-carousel/lib/styles.css";

import styles from './Testimonial.module.scss';

import wawu from "@images/wawu.png";
import seagreen from "@images/seagreen_logo.png";


const responsive =
{
    mobileMed: {
        breakpoint: { max: 5000, min: 360 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 360, min: 0 },
        items: 1
    }
};

const items =
    [
        {
            text: `Since the launch of our e-commerce business, ABC Cargo has been remarkable.\n
They consider your success, their success and it reflects in how quick they respond to concerns and the quality of service they deliver.
\n
Not to mention, they probably have the most affordable rates around. We at wawu global are glad to have them as our logistics partner.`,
            img: wawu,
            name: "Babafemi Olukorode",
            company: "Marketing Lead, Wawu Global"
        },
        {
            text: `
As a company, we had been faced with logistic challenges for a period of time, until we discovered ABC Cargo.\n\nIn the beginning, we were skeptical about their services but, we decided to give them a trial. Ever since we started with them, they have been very efficient and always improving on their services.\n\nTheir response to customer queries have been top notch.`,
            img: seagreen,
            name: "Andy Wahab",
            company: "HOD, Logistics, Seagreen Pharmaceuticals Ltd."
        },
        // {
        //     text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n Nobis minus vitae corporis non laudantium nam ducimus placeat voluptatem error culpa debitis quod praesentium, repellendus facilis quas soluta provident nemo consectetur.`,
        //     img: wawu,
        //     name: "Babafemi Olukorode",
        //     company: "Marketing Lead, Wawu Global"
        // },
    ];

const Card = ( { text, img, name, company } ) =>
{
    return (
        <article className={ styles.card }>

            <p className={ styles.text }>{ text }</p>

            <div className={ styles.cust }>
                <div className={ styles.img }>
                    <img src={ img } alt={ `${ company } logo` } />
                </div>
                <aside>
                    <p>{ name }</p>
                    <p>{ company }</p>
                </aside>
            </div>

        </article>
    );
};

const ButtonGroup = ( { next } ) =>
{
    return (
        <div className={ styles.next }>
            <button onClick={ () => next() }>
                <i className="las la-angle-right"></i>
            </button>
        </div>
    );
};

const Testimonial = () =>
{
    return (
        <section className={ styles.wrapper }>

            <Container className={ styles.container }>
                <aside>
                    <LinedText className={ styles.line }>Clients</LinedText>
                    <LinedText className={ styles.line }>That</LinedText>
                    <LinedText className={ styles.line }>Trust</LinedText>
                    <LinedText className={ styles.line }>Our</LinedText>
                    <LinedText className={ styles.line }>Services</LinedText>
                </aside>

                <Carousel
                    swipeable={ true }
                    draggable={ true }
                    showDots={ false }
                    responsive={ responsive }
                    infinite={ true }
                    autoPlay={ true }
                    autoPlaySpeed={ 3500 }
                    transitionDuration={ 500 }
                    arrows={ false }
                    renderButtonGroupOutside={ true }
                    customButtonGroup={ <ButtonGroup /> }
                    containerClass={ styles[ "carousel-container" ] }
                >
                    {
                        items.map( ( it, i ) => <Card key={ i } { ...it } /> )
                    }
                </Carousel>
            </Container>

        </section>

    );
};

export { Testimonial };
