import 
{
    format,
    lastDayOfMonth,
    startOfMonth,
    compareDesc
} from "date-fns";

const request = ( state ) => (
    {
        ...state,
        error: null,
        loading: true,
        status: "pending"
    }

);

const failure = ( state, error ) => (
    {
        ...state,
        loading: false,
        error: error,
        status: "rejected"
    }

);

const dispatcher = ( type, payload ) =>
{
    return { type, payload };
};

const formatDate = ( value, style = "yyyy/LL/dd" ) =>
{
    if ( !value ) return "N/A";
    return format( new Date( value ), style );
};


const formatCurrency = ( value ) =>
{
    return !isNaN( +value ) ? `₦${ ( new Intl.NumberFormat( 'en-NG' ) ).format( value ) }` : "N/A";
};

const getLastDay = () =>
{
    const last = new Date( lastDayOfMonth( new Date() ) );

    return formatDate( last );
};

const getFirstDay = () =>
{
    const first = new Date( startOfMonth( new Date() ) );

    return formatDate( first );
};

const sortDateDesc = ( items, key ) =>
{
    return [ ...items ].sort( ( a, b ) => compareDesc( new Date( a[ key ] ), new Date( b[ key ] ) ) );
};

const trimHubs = ( hubs ) =>
{
    const trimmed = hubs.map( ( { name, description, id, isActive } ) => (
        { name, id, description, isActive }
    ), {} );

    return trimmed;
};

const mapOrderStatus =
{
    "delivered item": "Arrived",
    "Picked Up": "Delivered",
    "delivered": "Arrived"

};

export
{
    request,
    failure,
    dispatcher,
    formatCurrency,
    formatDate,
    getFirstDay,
    getLastDay,
    sortDateDesc,
    trimHubs,
    mapOrderStatus
};
