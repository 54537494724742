import React from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "../";

import { formatDate, formatCurrency } from "../../utils/helpers";

const columns = [
  {
    Header: "Waybill no",
    accessor: "id",
  },
  {
    Header: "Staff",
    accessor: "staff",
  },
  {
    Header: "Receiver phone",
    accessor: "receiverPhoneNumber",
  },
  {
    Header: "Receiver name",
    accessor: "receiverName",
  },
  {
    Header: "Origin Hub",
    accessor: "originHubId",
  },
  {
    Header: "Destination Hub",
    accessor: "destinationHubId",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
];

const formatData = (data, isCol) => {
  const res = data
    ? data.map((d) => ({
        ...d,
        createdAt: isCol ? formatDate(d.dateCreated) : formatDate(d.createdAt),
        amount: isCol
          ? formatCurrency(d.totalAmount)
          : `${formatCurrency(d.amount)}`,
        receiverName: `${d.receiverFirstName} ${d.receiverLastName}`,
        receiverPhoneNumber: `+${d.receiverPhoneNumber}`,
        ...(isCol
          ? {}
          : {
              staff: d.staff
                ? `${d?.staff?.aspNetUser?.firstName} ${d?.staff?.aspNetUser?.lastName}`
                : "-",
            }),
      }))
    : [];
  return res;
};

const OrdersTable = ({ data }) => {
  const navigate = useNavigate();

  const goTo = (row) => {
    navigate(`/dashboard/your-orders/${row.id}`);
  };

  return (
    <div>
      <div> </div>
      <Table
        data={formatData(data)}
        paginate
        bordered
        columns={columns}
        fieldForTotal="amt"
        rowClickHandler={goTo}
      />
    </div>
  );
};

const cols = [
  {
    Header: "Waybill no",
    accessor: "orderId",
  },
  {
    Header: "Receiver phone",
    accessor: "receiverPhoneNumber",
  },
  {
    Header: "Receiver name",
    accessor: "receiverName",
  },
  {
    Header: "Origin Hub",
    accessor: "departureHubName",
  },
  {
    Header: "Destination Hub",
    accessor: "destinationHubName",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
];

const CollectionsTable = ({ data }) => {
  const navigate = useNavigate();

  const goTo = (row) => {
    navigate(`/dashboard/your-orders/${row.orderId}?fromCol=true`);
  };

  return (
    <div>
      <Table
        data={formatData(data, true)}
        paginate
        bordered
        columns={cols}
        fieldForTotal="amt"
        rowClickHandler={goTo}
      />
    </div>
  );
};

export { OrdersTable, CollectionsTable };
