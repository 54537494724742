import React from 'react';
import classNames from 'classnames';


import styles from './Card.module.scss';


const Card = (
    {
        title,
        icon,
        data,
        footer,
        wrapperClass,
        iconClass,
        footerClass,
        dataClass,
        titleClass,
    }
) => 
{
    return (
        <div className={ classNames( styles.wrapper, wrapperClass ) }>

            <div>

                <section className={ classNames( styles.title, titleClass ) }>
                    { title }
                </section>

                <section className={ classNames( styles.data, dataClass ) }>
                    { data }
                </section>

                <section className={ classNames( styles.footer, footerClass ) }>
                    { footer }
                </section>

            </div>

            <div className={ classNames( styles.icon, iconClass ) }>
                { icon }
            </div>

        </div>
    );
};

export { Card };
