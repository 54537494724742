import { lazy } from "react";

import Home from "./Normal/Home";
import Login from "./Normal/Auth/Login";
import SignUp from "./Normal/Auth/SignUp";
import ResetPassword from "./Normal/Auth/ResetPassword";
import Hubs from "./Normal/Hubs";
import About from "./Normal/About";
import Business from "./Normal/Business";
import Services from "./Normal/Services";
import Contact from "./Normal/Contact";
import Legal from "./Normal/Legal";
import PlaceOrder from "./Normal/Home";
import Quote from "./Normal/GetQuote";
import NotFound from "./NotFound";
import EcommerceRegister from "./Normal/Auth/EcommerceRegister";

const DBHome = lazy(() => import(`../fromOld/pages/Dashboard/Home`));
const Orders = lazy(() => import(`../fromOld/pages/Dashboard/Orders`));
const Transactions = lazy(() =>
  import(`../fromOld/pages/Dashboard/Transactions`)
);
const Profile = lazy(() => import(`../fromOld/pages/Dashboard/Profile`));
const TopWallet = lazy(() => import(`../fromOld/pages/Dashboard/TopWallet`));
const TrackOrder = lazy(() => import(`../fromOld/pages/Dashboard/TrackOrder`));

const Receipt = lazy(() => import(`../fromOld/pages/Dashboard/Orders/Receipt`));
const OrderDetails = lazy(() =>
  import(`../fromOld/pages/Dashboard/Orders/OrderDetails`)
);

const CollectionOrders = lazy(() =>
  import(`../fromOld/pages/Dashboard/Orders/CollectionOrders`)
);

export {
  Home,
  Hubs,
  Login,
  ResetPassword,
  SignUp,
  About,
  Business,
  Services,
  Contact,
  PlaceOrder,
  Quote,
  NotFound,
  Legal,
  DBHome,
  Receipt,
  Orders,
  TrackOrder,
  Transactions,
  Profile,
  TopWallet,
  OrderDetails,
  CollectionOrders,
  EcommerceRegister,
};
