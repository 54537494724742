import { hubsConstants } from '../constants';
import 
{
    request,
    failure
} from "../../utils/helpers";

const initialState =
{
    hubs: [],
    cityHubs: [],
    stateHubs: [],
    cityHubsStatus: "idle",
    stateHubsStatus: "idle",
    status: "idle",
    error: null,
    cityHubsErr: null,
    stateHubsErr: null,
    loading: false,
};

export function hubs ( state = initialState, action )
{
    const { type, payload } = action;
    switch ( type )
    {
        case hubsConstants.FETCH_HUBS_REQUEST:
            return request( state );

        case hubsConstants.FETCH_FADEYI_HUB_REQUEST:
        case hubsConstants.FETCH_HUBS_BY_CITY_REQUEST:
            return {
                ...state,
                cityHubsErr: null,
                cityHubsStatus: "pending"
            };

        case hubsConstants.FETCH_HUBS_BY_STATE_REQUEST:
            return {
                ...state,
                stateHubsErr: null,
                stateHubsStatus: "pending"
            };

        case hubsConstants.FETCH_HUBS_FAILURE:
            return failure( state, action.payload );

        case hubsConstants.FETCH_FADEYI_HUB_FAILURE:
            return {
                ...state,
                cityHubs: null,
                cityHubsErr: action.payload,
                cityHubsStatus: "rejected"
            };

        case hubsConstants.FETCH_HUBS_BY_CITY_FAILURE:
            return {
                ...state,
                cityHubs: [],
                cityHubsErr: action.payload,
                cityHubsStatus: "rejected"
            };


        case hubsConstants.FETCH_HUBS_BY_STATE_FAILURE:
            return {
                ...state,
                stateHubs: [],
                stateHubsErr: action.payload,
                stateHubsStatus: "rejected"
            };

        case hubsConstants.FETCH_HUBS_BY_CITY_SUCCESS:
            return {
                ...state,
                cityHubs: action.payload,
                cityHubsErr: null,
                cityHubsStatus: "resolved"
            };


        case hubsConstants.FETCH_HUBS_BY_STATE_SUCCESS:
            return {
                ...state,
                stateHubs: action.payload,
                stateHubsErr: null,
                stateHubsStatus: "resolved"
            };


        case hubsConstants.FETCH_FADEYI_HUB_SUCCESS:
            return {
                ...state,
                cityHubs: action.payload ? [ action.payload ] : [],
                cityHubsErr: null,
                cityHubsStatus: "resolved"
            };


        case hubsConstants.FETCH_HUBS_SUCCESS:
            return {
                ...state,
                loading: false,
                hubs: payload,
                status: "resolved"
            };

        case hubsConstants.SET_STATUS:
            const { key, value } = action.payload;
            return {
                ...state,
                [ key ]: value,
            };

        default:
            return state;
    }
}
