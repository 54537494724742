// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__1GAkT {\n  padding: 10px;\n}", "",{"version":3,"sources":["webpack://src/fromOld/components/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ","sourcesContent":[".container\n{\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__1GAkT"
};
export default ___CSS_LOADER_EXPORT___;
