// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonLink_btnLink__2iaxq {\n  text-align: center;\n  color: #fff;\n  font-family: \"Gilroy\";\n  font-weight: 500;\n  border: 1px solid #CB392A;\n  border-radius: 10px;\n  background-color: #CB392A;\n  padding: 15px;\n  font-size: 12px;\n  line-height: 12px;\n}\n.ButtonLink_btnLink__2iaxq.ButtonLink_outline__1EWBO {\n  background-color: #fff;\n  color: #CB392A;\n}", "",{"version":3,"sources":["webpack://src/components/UI/ButtonLink/ButtonLink.module.scss"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,yBAAA;EAEA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAKI;EAEI,sBAAA;EACA,cAAA;AAJR","sourcesContent":["@import \"../../../assets/scss/index.scss\";\n\n.btnLink\n{\n    text-align: center;\n    color:#fff;\n    font-family: \"Gilroy\";\n    font-weight: 500;\n    border: 1px solid #CB392A;\n;\n    border-radius: 10px;\n    background-color:  #CB392A;\n    padding:15px;\n    font-size: 12px;\n    line-height: 12px;\n\n    &.outline\n    {\n        background-color: #fff;\n        color:#CB392A;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnLink": "ButtonLink_btnLink__2iaxq",
	"outline": "ButtonLink_outline__1EWBO"
};
export default ___CSS_LOADER_EXPORT___;
