import { ordersConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";

import {
  dispatcher,
  // getLastDay,
  // getFirstDay,
  sortDateDesc,
  formatDate,
} from "../../utils/helpers";
import { addDays } from "date-fns";

const getCollectionOrders =
  (dateFrom = "", dateTo = "", customerId = "") =>
  async (dispatch, getState) => {
    const dF = formatDate(addDays(new Date(), -20));
    const dT = formatDate(new Date());

    const body = {
      dateFrom: dateFrom || dF,
      dateTo: dateTo || dT,
      customerId: customerId || getState().auth.user.id,
    };

    dispatch(dispatcher(ordersConstants.FETCH_USER_COLL_ORDERS_REQUEST));

    const data = await makeRequest({
      path: `Collections/GetCollectionByCustomer?customerId=${body.customerId}&dateFrom=${body.dateFrom}&dateTo=${body.dateTo}`,
      method: "GET",
    });

    if (data.error) {
      dispatch(
        dispatcher(ordersConstants.FETCH_USER_COLL_ORDERS_FAILURE, data.error)
      );
    } else {
      const res = JSON.parse(data.data);
      dispatch(
        dispatcher(
          ordersConstants.FETCH_USER_COLL_ORDERS_SUCCESS,
          sortDateDesc(res, "createdAt")
        )
      );
    }
  };

const getCustomerOrders =
  (dateFrom = "", dateTo = "", customerId = "") =>
  async (dispatch, getState) => {
    const dF = formatDate(addDays(new Date(), -20));
    const dT = formatDate(new Date());

    const body = {
      dateFrom: dateFrom || dF,
      dateTo: dateTo || dT,
      customerId: customerId || getState().auth.user.id,
    };

    dispatch(dispatcher(ordersConstants.FETCH_USER_ORDERS_REQUEST));

    const data = await makeRequest({
      path: "Orders/ListByCustomer",
      method: "POST",
      body,
    });

    if (data.error) {
      dispatch(
        dispatcher(ordersConstants.FETCH_USER_ORDERS_FAILURE, data.error)
      );
    } else {
      const res = JSON.parse(data.data);
      dispatch(
        dispatcher(
          ordersConstants.FETCH_USER_ORDERS_SUCCESS,
          sortDateDesc(res, "createdAt")
        )
      );
    }
  };

export const ordersActions = {
  getCustomerOrders,
  getCollectionOrders,
};
