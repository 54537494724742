import classNames from "classnames";

import styles from './Text.module.scss';


const Header = ( { children, center, className, ...props } ) =>
{
    return (
        <h2
            className={ classNames( styles.header,
                {
                    [ styles.center ]: center
                }
                , className ) }
            { ...props }>
            { children }
        </h2>
    );
};

export { Header };
