import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { Toggler } from "./Toggler";
import { Container, ButtonLink } from "@components";

import { authActions, userActions, hubActions } from "@redux/actions";

import { checkLogin } from "@utils";

import styles from "./NavBar.module.scss";

import logo from "@images/logoo.png";
import ecommerce from "@images/ecommerce.svg";

const paths = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "About us",
    path: "/about",
  },
  // {
  //     text: "Business",
  //     path: "/business"
  // },
  {
    text: "Our services",
    path: "/services",
  },
  {
    text: "Contact us",
    path: "/contact",
  },
  {
    text: "Our branches",
    path: "/hubs",
  },
];

const NavBar = () => {
  const { pathname } = useLocation();

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { getUserStatus } = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((s) => !s);
  };

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    const resize = window.addEventListener("resize", () => {
      if (isMounted) {
        setIsOpen(false);
      }
    });

    return () => window.removeEventListener("resize", resize);
  }, [isMounted]);

  if (!user.token) {
    const abcUser = localStorage.getItem("abc-user");

    if (checkLogin() && abcUser) {
      const parsed = JSON.parse(abcUser);
      dispatch(authActions.setUser(parsed));
      dispatch(hubActions.getHubs());

      if (getUserStatus === "idle") {
        dispatch(userActions.getUserById(parsed.id));
      }
    }
  }

  const isEcommerce = pathname.includes("ecommerce");

  return (
    <nav className={styles.nav}>
      <div className={styles["wrapper"]}>
        <Container className={styles.navigation}>
          <NavLink to="/" className={styles.logo}>
            <img src={isEcommerce ? ecommerce : logo} alt="abc logo" />
          </NavLink>

          <Toggler onClick={toggle} open={isOpen} className={styles.toggler} />

          <ul
            onClick={() => setIsOpen(false)}
            className={classNames(styles.list, {
              [styles.open]: isOpen,
            })}
          >
            {paths.map((p) => (
              <li key={p.path}>
                <NavLink
                  to={p.path}
                  className={({ isActive }) =>
                    classNames(styles.link, {
                      [styles.active]: isActive,
                    })
                  }
                >
                  {p.text}
                </NavLink>
              </li>
            ))}

            {/* {
                            !pathname.includes( "login" ) ?
                                <li>
                                    <NavLink
                                        to={ user?.userName ? "/dashboard" : "/login" }
                                        className={ styles.link } >
                                        { user?.userName ? "Dashboard" : "Login" }
                                    </NavLink>
                                </li> : null
                        } */}

            {user.userName ? null : (
              <li>
                <NavLink
                  to={"/ecommerce"}
                  className={({ isActive }) =>
                    classNames(styles.link, {
                      [styles.active]: isActive,
                    })
                  }
                >
                  E-commerce
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                to={user?.userName ? "/dashboard" : "/login"}
                className={styles.link}
              >
                {user?.userName ? "Dashboard" : "Login"}
              </NavLink>
            </li>

            {!user?.userName ? (
              <li>
                <NavLink
                  to={"/sign-up"}
                  className={classNames(styles.link, styles.highlight)}
                >
                  Sign Up
                </NavLink>
              </li>
            ) : null}

            {/* 
                        <ButtonLink
                            outline
                            path="/place-order"
                            className={ styles.linkBtn }>
                            Place an Order
                        </ButtonLink> */}

            <ButtonLink path="/quote" className={styles.linkBtn}>
              Get a Quote
            </ButtonLink>
          </ul>
        </Container>
      </div>
    </nav>
  );
};

export { NavBar };
