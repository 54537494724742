export const quoteConstants =
{

    FETCH_DEP_HUBS_REQUEST: "FETCH_DEP_HUBS_REQUEST",
    FETCH_DEP_HUBS_SUCCESS: "FETCH_DEP_HUBS_SUCCESS",
    FETCH_DEP_HUBS_FAILURE: "FETCH_DEP_HUBS_FAILURE",

    FETCH_ARR_HUBS_REQUEST: "FETCH_ARR_HUBS_REQUEST",
    FETCH_ARR_HUBS_SUCCESS: "FETCH_ARR_HUBS_SUCCESS",
    FETCH_ARR_HUBS_FAILURE: "FETCH_ARR_HUBS_FAILURE",


    FETCH_SERVICE_TYPES_REQUEST: "FETCH_SERVICE_TYPES_REQUEST",
    FETCH_SERVICE_TYPES_SUCCESS: "FETCH_SERVICE_TYPES_SUCCESS",
    FETCH_SERVICE_TYPES_FAILURE: "FETCH_SERVICE_TYPES_FAILURE",

    FETCH_DELIVERY_TYPES_REQUEST: "FETCH_DELIVERY_TYPES_REQUEST",
    FETCH_DELIVERY_TYPES_SUCCESS: "FETCH_DELIVERY_TYPES_SUCCESS",
    FETCH_DELIVERY_TYPES_FAILURE: "FETCH_DELIVERY_TYPES_FAILURE",


    FETCH_SHIPMENT_TYPES_REQUEST: "FETCH_SHIPMENT_TYPES_REQUEST",
    FETCH_SHIPMENT_TYPES_SUCCESS: "FETCH_SHIPMENT_TYPES_SUCCESS",
    FETCH_SHIPMENT_TYPES_FAILURE: "FETCH_SHIPMENT_TYPES_FAILURE",

    FETCH_ITEM_TYPES_REQUEST: "FETCH_ITEM_TYPES_REQUEST",
    FETCH_ITEM_TYPES_SUCCESS: "FETCH_ITEM_TYPES_SUCCESS",
    FETCH_ITEM_TYPES_FAILURE: "FETCHITEMY_TYPES_FAILURE",



    SET_STATUS: "SET_STATUS"

};
