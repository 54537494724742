import { useCallback, useState } from 'react';
import
{
    GoogleMap,
    useLoadScript,
    Marker,
    OverlayView
} from '@react-google-maps/api';
import Geocode from "react-geocode";

import { Spinner1 } from "../../../fromOld/components";
import mapStyles from "./mapStyles.json";
import marker from "@images/icons/marker.png";

import styles from './MapItem.module.scss';

const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

Geocode.setApiKey( key );

const containerStyle =
{
    height: "300px"
};

const options =
{
    styles: mapStyles
};



const addressToLatLng = ( address, setCenter, setErr ) =>
{
    Geocode.fromAddress( address ).then(
        ( response ) =>
        {
            const { lat, lng } = response.results[ 0 ].geometry.location;
            setCenter( { lat, lng } );
        },
        ( error ) =>
        {
            setErr( "Could not load location on map" );
            console.error( error );
        }
    );
};

const getName = hub => 
{
    const name = hub.name.toLowerCase();

    if ( name.includes( "hub" ) )
    {
        return name;
    }

    return `${ name } hub`;
};


const MapItem = ( { hub, showAddress = true } ) => 
{
    const [ center, setCenter ] = useState( { lat: 0, lng: 0 } );
    const [ err, setErr ] = useState( false );

    const { isLoaded, loadError } = useLoadScript( {
        googleMapsApiKey: key
    } );

    const mapClick = () =>
    {
        const uri = hub.description;
        const encoded = encodeURI( uri );
        const url = `https://www.google.com/maps/search/?api=1&query=${ encoded }`;
        window.open( url, '_blank' );
    };

    const onLoad = useCallback( ( mapInstance ) =>
    {
        if ( hub.isActive )
        {
            addressToLatLng( hub.description, setCenter, setErr );

        }
    }
        , [ hub ] );

    const renderMap = () =>
    {

        return <GoogleMap
            onLoad={ onLoad }
            mapContainerStyle={ containerStyle }
            center={ center }
            zoom={ 16 }
            options={ options }
        >
            <Marker
                icon={ marker }
                position={ center }
                onClick={ mapClick }
            >
            </Marker>

            <OverlayView
                position={ center }
                mapPaneName={ OverlayView.OVERLAY_MOUSE_TARGET }
            >
                <p
                    onClick={ mapClick }
                    className={ styles.info }>
                    { getName( hub ) }
                </p>
            </OverlayView>

        </GoogleMap>;
    };


    if ( loadError )
    {
        return <div >Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? <div className={ styles.wrapper }>

        {
            err ? <h5 className={ styles.mapErr }>{ err }</h5> : renderMap()
        }

        <article>
            <h4>{ getName( hub ) }</h4>


            <p>{ showAddress ? hub.description : "" }</p>
        </article>
    </div> : <Spinner1 />;
};

export { MapItem };
