import classNames from "classnames";

import styles from './FormContainer.module.scss';


const FormContainer = ( {
    message,
    formClass,
    submitHandler,
    children,
    msgClass,
    ...formProps } ) =>
{
    return (
        <form
            className={ classNames( styles.form, formClass ) }
            onSubmit={ submitHandler }
            { ...formProps }>

            <p className={ classNames( styles.msg, msgClass ) }>
                { message }
            </p>

            { children }

        </form>
    );
};

export { FormContainer };
