import React from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import 
{
    Container,
    Header,
    Inputs,
    ButtonLink,
    FormContainer,
    ContactSection,
    //Subscribe

} from "@components";

import styles from './Page.module.scss';


const schema = yup.object().shape(
    {
        fullName: yup.string().required( "Name is required" ),
        email: yup.string().required( "Email is required" )
            .email( "Please input a valid email" ),
        message: yup.string().required( "Message is required" ),
    }
).required();



const Contact = () =>
{

    const { register, formState: { errors }, handleSubmit } = useForm( {
        resolver: yupResolver( schema )
    } );

    const onSubmit = data => 
    {
        console.log( data );
    };

    return (
        <>
            <Container>

                <br />

                <Header center>Contact Us </Header>

                <FormContainer
                    message={ `Send us a message\n\nHi there, your feedback means a great deal to us` }
                    submitHandler={ handleSubmit( onSubmit ) }
                    formClass={ styles.formClass }
                >

                    <br />


                    <Inputs
                        inputProps={
                            {
                                ...register( "fullName" ),
                                name: "fullName",

                            }
                        }
                        label="Full name"
                        labelClass={ styles.label }
                        error={ errors.fullName && errors.fullName?.message }
                    />

                    <Inputs
                        inputProps={
                            {
                                ...register( "email" ),
                                name: "email",

                            }
                        }
                        label="Email"
                        labelClass={ styles.label }
                        type="email"
                        error={ errors.email && errors.email?.message }
                    />

                    <Inputs
                        inputProps={
                            {
                                ...register( "message" ),
                                name: "message",

                            }
                        }
                        type="textarea"
                        labelClass={ styles.label }
                        label="Input Feedback"
                        error={ errors.message && errors.message?.message }
                    />

                    <ButtonLink className={ styles.submit }>
                        Send Feedback
                    </ButtonLink>

                </FormContainer>
            </Container>

            {/* <Container>
                <Subscribe
                    header={
                        <Header center>Contact Us </Header> }
                    message={
                        <>

                            <p>Hi there, your feedback means a great deal to us,</p>

                            <p>Send us a message.</p>


                        </>
                    } />
            </Container> */}

            <ContactSection />
        </>
    );
};

export default Contact;
