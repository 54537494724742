import React from 'react';
import styles from './Retry.module.scss';


const Retry = ( { retry } ) =>
{
    return (
        <button
            onClick={ retry }
            className={ styles.retry }>
            Retry
        </button>
    );
};

export { Retry };
