import React from 'react';
import SelectSearch from 'react-select-search';

import Fuse from "fuse.js";

import 'react-select-search/style.css';

function fuzzySearch ( options )
{
    const fuse = new Fuse( options, {
        keys: [ 'name', 'groupName', 'items.name', "value" ],
        threshold: 0.3,
    } );

    return ( value ) =>
    {
        if ( !value.length )
        {
            return options;
        }

        return fuse.search( value );
    };
}


const SelectSearchDropDown = (
    {
        options,
        search = true,
        name = "Pick",
        value,
        disabled,
        onChange,
        filterOptions,
        placeholder = "Choose...",
        label
    }
) =>
{
    return (
        <div>
            { label ? label : null }
            <SelectSearch
                options={ options }
                search={ search }
                name={ name }
                value={ value }
                disabled={ disabled }
                onChange={ onChange }
                filterOptions={ filterOptions || fuzzySearch }
                placeholder={ placeholder } />
        </div>

    );
};

export { SelectSearchDropDown };
