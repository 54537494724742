import * as yup from "yup";
import { isPossiblePhoneNumber } from 'react-phone-number-input';


import 
{
    format,
    lastDayOfMonth,
    startOfMonth,
    compareDesc
} from "date-fns";


const mapOrderStatus =
{
    "delivered item": "Arrived",
    "Picked Up": "Delivered",
    "delivered": "Arrived"

};

const request = ( state ) => (
    {
        ...state,
        error: null,
        loading: true,
        status: "pending"
    }

);

const failure = ( state, error ) => (
    {
        ...state,
        loading: false,
        error: error,
        status: "rejected"
    }

);

const dispatcher = ( type, payload ) =>
{
    return { type, payload };
};

const formatDate = ( value, style = "yyyy/LL/dd" ) =>
{
    if ( !value ) return "N/A";
    return format( new Date( value ), style );
};


const formatCurrency = ( value ) =>
{
    return +value ? `₦${ ( new Intl.NumberFormat( 'en-NG' ) ).format( value ) }` : "N/A";
};

const getLastDay = () =>
{
    const last = new Date( lastDayOfMonth( new Date() ) );

    return formatDate( last );
};

const getFirstDay = () =>
{
    const first = new Date( startOfMonth( new Date() ) );

    return formatDate( first );
};

const sortDateDesc = ( items, key ) =>
{
    return [ ...items ].sort( ( a, b ) => compareDesc( new Date( a[ key ] ), new Date( b[ key ] ) ) );
};

const trimHubs = ( hubs ) =>
{
    const trimmed = hubs.map( ( { name, id } ) => (
        { name, id }
    ), {} );

    return trimmed;
};

const checkLogin = () =>
{
    const min = 1000 * 60;
    const hr = 60 * min;

    const interval = ( 6 * hr ) + ( min * 50 );

    const now = new Date().getTime();
    const last = +localStorage.getItem( 'lastLogged' );

    if ( !last )
    {
        return false;
    }
    else
    {

        const diff = now - last;

        if ( diff > interval )
        {
            return false;
        }

        return true;
    }





};


function isValidPhone ( message = "Please input a valid phone number" )
{
    return this.test( "isValidPhone", message, function ( value )
    {
        const { path, createError } = this;

        const num = value ? ( value[ 0 ] === "+" ? value : `+${ value }` ) : "";

        if ( value && !isPossiblePhoneNumber( num ) )
        {
            return createError( { path, message } );
        }

        return true;
    } );
}
yup.addMethod( yup.mixed, "isValidPhone", isValidPhone );

export
{
    request,
    failure,
    dispatcher,
    formatCurrency,
    formatDate,
    getFirstDay,
    getLastDay,
    sortDateDesc,
    trimHubs,
    checkLogin,
    mapOrderStatus
};
