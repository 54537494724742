import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { makeRequest } from "@utils";


import 
{
    Container,
    Header,
    Inputs,
    ButtonLink,
    FormContainer,
    ContactSection

} from "@components";


import styles from '../Page.module.scss';

const schema = yup.object().shape(
    {

        phoneNumber: yup.string().required( "Phone number is required" ).isValidPhone(),

    }

).required();


const ResetPassword = () =>
{

    const navigate = useNavigate();

    const [ reset, setReset ] = useState(
        {
            status: "idle",
            error: null,
            data: null
        } );


    const {
        watch,
        clearErrors,
        formState: { errors },
        control, handleSubmit } = useForm( {
            resolver: yupResolver( schema )
        } );


    const phone = watch( "phoneNumber" );

    const onSubmit = async ( values ) => 
    {
        clearErrors();


        const phone = values.phoneNumber.replace( "+", "" );

        setReset(
            {
                status: "pending",
                error: null,
                data: null
            }
        );


        let data = await makeRequest( {
            path: `Customers/GetDetailsByPhoneNumber?phoneNumber=${ phone }`
        } );

        if ( data.error )
        {
            setReset(
                {
                    status: "rejected",
                    error: data.error.toString(),
                    data: null
                }
            );
        }
        else
        {

            window.scrollTo( 0, 0 );

            const user = JSON.parse( data.data );

            data = await makeRequest( {
                path: `Customers/ResetPassword?customerId=${ user.id }`,
                method: "POST"
            } );


            if ( data.error )
            {
                setReset(
                    {
                        status: "rejected",
                        error: data.error.toString(),
                        data: null
                    }
                );
            }

            else
            {

                setReset(
                    {
                        status: "resolved",
                        error: null,
                        data: `Customer password reset sms has been sent your phone number ${ values.phoneNumber }. \n You will be redirected to login page in 5 seconds`
                    }
                );

                setTimeout( () =>
                {
                    navigate( "/login" );
                }, 5 * 1000 );
            }





        }
    };

    return (
        <>
            <Container>

                <br />

                <Header center>Reset Password</Header>

                <FormContainer
                    message={ `Please enter the phone number you used to register` }
                    submitHandler={ handleSubmit( onSubmit ) }
                    formClass={ styles.formClass }
                    autoComplete="off"
                >
                    <br />


                    { reset.error && (
                        <p className={ styles.formErr }>{ reset.error }</p>
                    ) }


                    { reset.data && <p className={ styles.success }>{ reset.data }</p> }

                    <br />

                    <Controller
                        name="phoneNumber"
                        control={ control }
                        render={
                            ( { field } ) => (
                                <Inputs
                                    field={ field }
                                    type="tel"
                                    inputProps={
                                        {
                                            name: "phoneNumber",
                                        }
                                    }
                                    label="Phone number"
                                    labelClass={ styles.label }
                                    error={ errors.phoneNumber && errors.phoneNumber?.message }
                                />
                            )
                        }
                    />




                    <ButtonLink
                        disabled={ reset.status === "pending" || !phone }
                        className={ styles.submit }>
                        Reset
                    </ButtonLink>

                </FormContainer>
            </Container>

            <ContactSection />
        </>
    );
};

export default ResetPassword;
