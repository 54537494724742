import React from 'react';
import classNames from "classnames";
import styles from './Spinners.module.scss';



const Spinner1 = () =>
{
    return (
        <div className={ styles.wrap }>
            <div className={ styles[ "sk-cube-grid" ] }>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube1" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube2" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube3" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube4" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube5" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube6" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube7" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube8" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube9" ] )
                }></div>
            </div>
        </div>
    );
};

const Spinner2 = () =>
{
    return (
        <div className={ styles.wrap }>
            <div className={ styles[ "sk-folding-cube" ] }>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube1" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube2" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube4" ] )
                }></div>
                <div className={
                    classNames( styles[ "sk-cube" ], styles[ "sk-cube3" ] )
                }></div>
            </div>
        </div>
    );
};

export 
{
    Spinner1,
    Spinner2
};
