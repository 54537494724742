// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contact_wrapper__3IOnP {\n  margin-top: 50px;\n  background: #FBFBFB;\n  padding: 20px;\n}\n\n.Contact_contact__3U-Ql {\n  margin: 10px auto;\n  max-width: 400px;\n}\n.Contact_contact__3U-Ql .Contact_row__1w89T {\n  display: grid;\n  grid-template-columns: 50px minmax(0, 1fr);\n  align-items: center;\n  margin: 20px 0;\n}", "",{"version":3,"sources":["webpack://src/components/Misc/Contact/Contact.module.scss"],"names":[],"mappings":"AAEA;EAEI,gBAAA;EACA,mBAAA;EACA,aAAA;AAFJ;;AAKA;EAEI,iBAAA;EACA,gBAAA;AAHJ;AAKG;EAEI,aAAA;EACA,0CAAA;EACA,mBAAA;EACA,cAAA;AAJP","sourcesContent":["@import \"../../../assets/scss/index.scss\";\n\n.wrapper\n{\n    margin-top: 50px;\n    background: #FBFBFB;\n    padding:20px;\n}\n\n.contact\n{\n    margin: 10px auto;\n    max-width: 400px;\n\n   .row\n   {\n       display: grid;\n       grid-template-columns: 50px minmax(0,1fr);\n       align-items: center;\n       margin: 20px 0;\n\n   } \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Contact_wrapper__3IOnP",
	"contact": "Contact_contact__3U-Ql",
	"row": "Contact_row__1w89T"
};
export default ___CSS_LOADER_EXPORT___;
