import React from 'react';

import { Container } from "@components";

import styles from './Contact.module.scss';

import location from "@images/icons/location.svg";
import mail from "@images/icons/mail.svg";
import call from "@images/icons/call.svg";


const ContactSection = () =>
{
    return (
        <section className={ styles.wrapper }>
            <Container className={ styles.contact }>

                <div className={ styles.row }>
                    <img src={ location } alt="location marker icon" />
                    <p>52C, Ikorodu Road Fadeyi, Lagos State. Nigeria
                    </p>
                </div>

                <div className={ styles.row }>
                    <img src={ mail } alt="mail icon" />
                    <p>cargoex@abctransport.com</p>
                </div>

                <div className={ styles.row }>
                    <img src={ call } alt="phone icon" />
                    <p>0813 986 2090, 08142552445, 080-63261919.</p>
                </div>

            </Container>
        </section>
    );
};

export { ContactSection };
