import { authConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import { dispatcher } from "../../utils/helpers";


const login = ( userName, password ) => async ( dispatch ) =>
{
    const body =
    {
        userName,
        password,
        platform: "Web"
    };

    dispatch( dispatcher( authConstants.LOGIN_USER_REQUEST ) );

    const data = await makeRequest( {
        path: "Home/Authrorize",
        method: "POST",
        body
    } );


    if ( data.error )
    {
        dispatch( dispatcher( authConstants.LOGIN_USER_FAILURE, data.error ) );
    }
    else
    {
        const res = JSON.parse( data.data );

        const user =
        {
            id: res.userId,
            token: res.token,
            userName
        };
        localStorage.setItem( "abc-user", JSON.stringify( user ) );
        localStorage.setItem( 'lastLogged', new Date().getTime() );
        dispatch( dispatcher( authConstants.LOGIN_USER_SUCCESS, user ) );
    }

};

const setUser = ( data ) => async ( dispatch ) =>
{
    dispatch( dispatcher( authConstants.LOGIN_USER_SUCCESS, data ) );
};

const logout = () => async ( dispatch ) =>
{
    localStorage.removeItem( 'abc-user' );
    localStorage.removeItem( 'lastLogged' );
    dispatch( dispatcher( authConstants.LOGOUT_USER_SUCCESS ) );
};


export const authActions =
{
    login,
    logout,
    setUser
};
