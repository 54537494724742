import { cityStateConstants } from '../constants';

const initialState =
{
    cities: [],
    states: [],
    citiesStatus: "idle",
    statesStatus: "idle",
    status: "idle",
    citiesErr: null,
    statesErr: null,
};

export function cityState ( state = initialState, action )
{
    switch ( action.type )
    {

        case cityStateConstants.FETCH_CITIES_REQUEST:
            return {
                ...state,
                citiesErr: null,
                citiesStatus: "pending"
            };

        case cityStateConstants.FETCH_STATES_REQUEST:
            return {
                ...state,
                statesErr: null,
                statesStatus: "pending"
            };

        case cityStateConstants.FETCH_CITIES_FAILURE:
            return {
                ...state,
                cities: [],
                citiesErr: action.payload,
                citiesStatus: "rejected"
            };

        case cityStateConstants.FETCH_STATES_FAILURE:
            return {
                ...state,
                states: [],
                statesErr: action.payload,
                statesStatus: "rejected"
            };

        case cityStateConstants.FETCH_CITIES_SUCCESS:
            return {
                ...state,
                cities: action.payload,
                citiesErr: null,
                citiesStatus: "resolved"
            };

        case cityStateConstants.FETCH_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload,
                statesErr: null,
                statesStatus: "resolved"
            };

        case cityStateConstants.SET_STATUS:
            const { key, value } = action.payload;
            return {
                ...state,
                [ key ]: value,
            };

        default:
            return state;
    }
}
