import React, { useState } from 'react';
import classNames from 'classnames';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import
{
    BsEye,
    BsEyeSlash
} from "react-icons/bs";

import 'react-phone-number-input/style.css';

import styles from './Inputs.module.scss';


const Inputs = (
    {
        wrapperClass,
        errorClass,
        className,
        register,
        errors,
        name,
        label,
        field,
        ...props } ) => 
{
    const [ show, setShow ] = useState( false );

    switch ( props.type ) 
    {
        case "textarea":
            return (
                <div className={ classNames( styles.fieldGrp, wrapperClass ) } >
                    <label htmlFor={ name }>{ label }</label>
                    <textarea
                        { ...register( name ) }
                        className={ classNames( styles.input, className ) }
                        id={ name }
                        { ...props }></textarea>
                    { errors[ name ] && <small>{ errors[ name ]?.message }</small> }
                </div> );

        case "select":
            const { options, ...p } = props;
            return (
                <div className={ classNames( styles.fieldGrp, wrapperClass ) } >
                    <label htmlFor={ name }>{ label }</label>
                    <select
                        id={ name }
                        { ...register( name ) }
                        className={ classNames( styles.input, className ) }
                        { ...p }>
                        <option value="">Select...</option>

                        {
                            options.map( p => (
                                <option key={ p.val } value={ p.val }>
                                    { p.label }</option>
                            ) )
                        }
                    </select>
                    { errors[ name ] && <small>{ errors[ name ]?.message }</small> }
                </div> );


        case "tel":
            return (
                <div className={ classNames( styles.fieldGrp, styles.phone, wrapperClass ) } >
                    <label htmlFor={ name }>{ label }</label>
                    <div className={ styles.input }>
                        <PhoneInput
                            { ...field }
                            international
                            defaultCountry="NG"
                            value={ formatPhoneNumberIntl( `+${ field.value }` ) || field.value }
                            countryCallingCodeEditable={ false }
                            placeholder="Enter phone number" />
                    </div>

                    { errors[ name ] && <small>{ errors[ name ]?.message }</small> }
                </div>
            );

        case "password":
            return (
                <div className={ classNames( styles.fieldGrp, wrapperClass ) } >
                    <label htmlFor={ name }>{ label }</label>
                    <input
                        id={ name }
                        { ...register( name ) }
                        className={ classNames( styles.input, className ) }
                        { ...props }
                        type={ show ? "text" : "password" } />

                    <button
                        onClick={ () => setShow( s => !s ) }
                        type="button"
                        className={ styles.icon } >
                        { show ? <BsEyeSlash /> : <BsEye /> }
                    </button>

                    { errors[ name ] && <small>{ errors[ name ]?.message }</small> }
                </div> );

        default:
            return (
                <div className={ classNames( styles.fieldGrp, wrapperClass ) } >
                    <label htmlFor={ name }>{ label }</label>
                    <input
                        id={ name }
                        { ...register( name ) }
                        className={ classNames( styles.input, className ) }
                        { ...props } />
                    { errors[ name ] && <small>{ errors[ name ]?.message }</small> }
                </div> );
    }



};

export { Inputs };
