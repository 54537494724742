// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptystate_empty__2zcnd {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  min-height: 70vh;\n}\n.emptystate_empty__2zcnd h3 {\n  font-weight: 600;\n  font-size: 21px;\n  line-height: 24px;\n  color: #232340;\n  margin-top: 46px;\n}\n.emptystate_empty__2zcnd p {\n  margin-top: 10px;\n  font-size: 14px;\n  line-height: 24px;\n  color: #748cad;\n  max-width: 300px;\n  text-align: center;\n}\n.emptystate_empty__2zcnd button {\n  height: 50px;\n  width: 150px;\n  margin-top: 20px;\n  color: #fff;\n  font-weight: 600;\n  background: #CB392A;\n  border-radius: 5px;\n  font-size: 16px;\n  border: none;\n}", "",{"version":3,"sources":["webpack://src/fromOld/components/EmptyState/emptystate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AACE;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".empty {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  min-height: 70vh;\n  h3 {\n    font-weight: 600;\n    font-size: 21px;\n    line-height: 24px;\n    color: #232340;\n    margin-top: 46px;\n  }\n  p {\n    margin-top: 10px;\n    font-size: 14px;\n    line-height: 24px;\n    color: #748cad;\n    max-width: 300px;\n    text-align: center;\n  }\n\n  button {\n    height: 50px;\n    width: 150px;\n    margin-top: 20px;\n    color: #fff;\n    font-weight: 600;\n    background: #CB392A;\n    border-radius: 5px;\n    font-size: 16px;\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "emptystate_empty__2zcnd"
};
export default ___CSS_LOADER_EXPORT___;
