import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import 
{
    Container,
    Header,
} from "@components";

import styles from './Legal.module.scss';


const Legal = () =>
{
    return (
        <Container className={ styles.wrapper }>

            <br />

            <br />
            <Header center >
                CARGO EXPRESS TERMS AND CONDITIONS FOR CARRIAGE
            </Header>

            <br />

            <div>

                <h4 className={ styles.title }>
                    DEFINITION OF TERMS
                </h4>

                <p>“We”, “us”, “our” and ABC    means ABC Transport; ABC Cargo Express; ABC Plc.; ABC Transport (Ghana) Ltd, subsidiaries and affiliates of ABC and ABC's employees, representatives and servants;

                </p>
                <p>
                    “You” and “Your”  means the sender, consignor, or consignee, receiver, and owner of the contents of the shipment or any other person having a legal interest in those contents;
                </p>
                <p>
                    “Carriage”   means and includes the whole of the operations and services undertaken by us in connection with the shipment;</p>
                <p>     “Shipment” and “consignment” means any goods or documents of whatever nature (whether in bulk or in one or more packages) which we have accepted for carriage from one destination to another whether under our waybill or not.</p>
                <p>  “Owner's Risk” means that the goods are held upon terms that we shall not be liable for any loss of whatsoever nature and howsoever caused including negligence in relation to the goods or as a consequence of the goods being in our possession. You will indemnify us against all claims that may be made against us arising from the carriage, retention or storage of such goods.</p>
                <p>

                    “Waybill” shall mean a single ABC waybill/consignment note or the entries recorded against the same date, address and service level on a collection record. All items/packages covered under a Waybill shall be considered a single shipment.
                </p>




            </div>

            <div>
                <h4 className={ styles.title }>
                    SCOPE OF CONTRACT
                </h4>

                <p>You acknowledge that you have read our terms and conditions of carriage, posted at our various terminals which is also contained on our website -&nbsp;
                    <NavLink to="/" className={ styles.link }>
                        www.abccargoxpress.com
                    </NavLink>
                    &nbsp;
                    and have accepted them. By concluding any type of contract with us and giving us your consignment, you accept our terms and conditions set out in this waybill and on our website -&nbsp;
                    <NavLink to="/" className={ styles.link }>
                        www.abccargoxpress.com
                    </NavLink>
                    , on behalf of yourself and/or anyone else who has an interest in the shipment irrespective of whether you have signed the front of our waybill or not. No agent, servant or representative of ABC has the authority to modify, alter or waive any provision of this contract. When you give us the consignment with oral or written instructions which conflict with our terms and conditions, we shall not be bound by such instructions.

                </p>
                <p>
                    You acknowledge and accept that shipments will be consolidated with those of other shippers for transport. We may not monitor the inbound and outbound movement of individual shipments at all handling centres and hold us harmless against any loss/damage that may result from such consolidation.</p>
                <p>

                    ABC is not a common carrier and reserves the right in its absolute discretion to refuse carriage to any package/cargo tendered to it for transportation.</p>

            </div>


            <div>
                <h4 className={ styles.title }>

                    DOCUMENTATION OF CONSIGNMENT
                </h4>

                <p>
                    You agree that you shall declare the actual contents of your consignment and be bound by the description as written on our waybill. The names, addresses and telephone numbers of the consignor and consignee shall be provided by you and you agree that we shall not be liable for any loss or delay in delivery occasioned by your failure to provide these details. You undertake that it is your responsibility to declare the value of your shipment, and agree that we are not bound to request such information from you. That where you fail to make such declaration to us and which must be written on the waybill, you shall hold us harmless and free from any liability arising from any loss or damage to your shipment howsoever caused, and that the said shipment is shipped at owner's risk.
                </p>


            </div>

            <div>
                <h4 className={ styles.title }>
                    PACKAGING OF CONSIGNMENTS
                </h4>

                <p>
                    You shall be solely responsible for the packaging of your consignment in such a way that it can withstand normal handling. Consignments of fragile nature must be packed in crates with foam, foam pellets, and other protective casing with a “fragile” tag attached thereto. Any loss or damage to your consignment arising out of your failure to pack it in an aforesaid manner is strictly at your cost.
                </p>
            </div>

            <div>
                <h4 className={ styles.title }>
                    PROHIBITED/DANGEROUS GOODS
                </h4>

                <p>
                    ABC does not carry illegal or prohibited goods, which are goods prohibited by law, rule or regulation of any country in which the shipment travels. We do not carry animals, currency, bearer forms, negotiable instruments, precious metals and stones, firearms/parts thereof and ammunitions, human remains, pornography and illegal narcotics and drugs. You agree that your consignment is acceptable for carriage and undertake to indemnify ABC for any customs duties, taxes, penalties and other expenses or charges which we incur as a result of the actions of customs or other governmental authorities for your failure to provide proper documentations and/or obtain the required license or permit. You acknowledge that in the event that you make a fraudulent or untrue statement about the shipment or any of its contents you risk a civil claim and/or criminal prosecution the penalties for which include forfeiture and sale of your shipment. You agree to indemnify us and hold us harmless from any claims that may be brought against us arising from the carriage of your consignment and any costs that we may incur regarding this.

                </p>
                <p>
                    Perishable and temperature sensitive goods, which include but not limited to fruits, vegetables, flowers, meat, poultry, all types of bakery and other related goods may be accepted for shipment, provided it is entirely at owner's risk

                </p>
            </div>

            <div>

                <h4 className={ styles.title }>
                    INSPECTION
                </h4>

                <p>ABC reserves the right, but is not obliged, to open and inspect any shipment tendered to it for transportation at any time, without prior notice to you.</p>
            </div>

            <div>

                <h4 className={ styles.title }>
                    CHARGES AND PAYMENTS
                </h4>

                <p>
                    ABC's shipment charges are calculated according to the higher or actual or volumetric weight and any shipment may be re-measured and re-weighed by ABC to confirm this calculation. Our carriage charges are calculated in accordance with the rates applicable as set out in our current rate card. You must insist that official receipts are issued for each payment made. You agree to pay or reimburse us for all shipment charges, storage charges (demurrage), duties and taxes owed for services provided by ABC or incurred by ABC on your behalf and all claims, damages, fines and expenses incurred if the shipment is deemed unacceptable for transport as described in Section 5. Cancellation of the contract when a waybill has been issued attracts a service charge of 15% of the amount for carriage.
                </p>
            </div>

            <div>
                <h4 className={ styles.title }>
                    ABC'S LIABILITY
                </h4>

                <p>
                    ABC contracts with consignor on the basis that our liability is strictly limited to direct loss only and to the limits in this Section 8. If we carry your shipment by road within to or from a country that is a party to the Convention on the Contract for the International Carriage of Goods by Road 1956 (CMR), our liability for loss or damage to your shipment shall be governed by the CMR. If we have a liability to you for whatever reason including without limitation breach of contract, negligence, willful act or default, and such liability is not governed by the aforementioned Convention, ABC's liability to you is at all times limited to the actual amount of any loss or damage to the consignment and for the purpose of this contract we mean the actual value of the shipment .The actual cost of the shipment shall be the cost of repairing or replacing the consignment whichever is lower, within every case an upper limit that does not exceed ₦150 per kilo with a maximum of ₦10,000 per shipment.
                </p>
            </div>

            <div>

                <h4 className={ styles.title }>
                    EXCLUSIONS
                </h4>

                <ol className={ classNames( styles.list, styles.lettered ) }>

                    <li>
                        ABC SHALL NOT BE LIABLE in any event for any consequential or special damages or other direct and indirect loses however arising whether or not limited to loss of income, profits, interest, utility or loss of market.
                    </li>

                    <li>
                        We shall not be liable for any loss or damage including but not limited to lost profit, loss of income, loss of interest, loss of future business, loss of market, loss of reputation, loss of commercial personal value, incidental, special or consequential damages or loss howsoever arising including but not limited to the loss, damage, delay, mis-delivery or non-delivery of your shipment whether the risk of such loss or damage was brought to our attention before or after acceptance of the shipment since special risks can be insured by the consignor.
                    </li>


                    <li>
                        We shall make every reasonable effort to deliver the shipment according to our regular delivery schedules, but these are not guaranteed and do not form part of the contract. ABC is not liable for any loss or damage caused by delays.
                    </li>

                    <li>
                        We are not liable if your shipment or any part of it is lost, damaged, delayed or misdelivered or not delivered or if we do not fulfill any obligations towards you as a result of:

                        <br />

                        <ul className={ classNames( styles.list, styles.roman ) }>

                            <li>
                                Circumstances beyond our control such as (but not limited to):

                                <ul className={ styles.list }>
                                    <li>

                                        Acts of God including earthquakes, storms, floods, fire, disease, cyclones, fog, snow, or frost;
                                    </li>
                                    <li>
                                        Force majeure includes but is not limited to war, accidents, acts of public enemies, strikes, embargoes, riots, local disputes, or civil commotion.


                                    </li>
                                    <li>                                      National or local disruptions in road transportation networks and mechanical problems to modes of transport or machinery or bad roads.

                                    </li>
                                    <li>                                       Latent defects or inherent vice in the contents of the shipment;


                                    </li>
                                    <li>                        Criminal acts of third parties such as theft and arson.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Your acts or omissions or those of third parties such as:

                                <ul className={ styles.list }>
                                    <li>

                                        A breach by you (or any other party claiming interest in the shipment) or your obligations under these terms and conditions and in particular the warranties set out herein.

                                    </li>

                                    <li>
                                        An act or omission of any authority or agent of the government.

                                    </li>

                                    <li>                                     The contents of the shipment consisting of any article that is a prohibited item even though we may have accepted the consignment in error.

                                    </li>
                                </ul>
                            </li>

                        </ul>


                    </li>

                    <li>
                        Demurrage will be attracted by waybills that stay beyond 3 days on arrival at terminals
                    </li>

                    <li>
                        Consignments abandoned at terminals in excess of one calendar year from the date of arrival shall be disposed at the discretion of the carrier
                    </li>

                    <li>
                        After an attempt at Home delivery, the customer will retrieve it at the terminal or make arrangements for another delivery.
                    </li>
                </ol>


            </div>

            <div>
                <h4 className={ styles.title }>
                    CLAIMS PROCEDURE
                </h4>

                <p>
                    Claims are limited to one claim per shipment settlement of which will be a full and final settlement for all loss or damage in connection therewith. You undertake to us that you shall not permit any other person who has interest in the shipment to bring a claim or action against us arising from the carriage even though we may have been negligent or in default and if a claim or action is made, you will indemnify us against the consequences of the claim and the costs and expenses we incur in defending it. All claims must be submitted in writing to ABC within thirty (30) days from the date we accepted the consignment at the terminal of dispatch, failing which we shall have no liability whatsoever. We are not obliged to act on any claim until our carriage charges have been paid nor are you entitled to deduct the amount of your claim from our carriage charges. The shipment will be deemed to have been delivered in good condition, once you accept the shipment and sign off on the appropriate portion of the waybill unless the receiver has noted any damage on the delivery record when he/she accepted the shipment.
                </p>
            </div>

            <div>
                <h4 className={ styles.title }>
                    CONSIGNOR'S WARRANTIES AND INDEMNITY
                </h4>

                <p>
                    You shall indemnify and hold ABC harmless for any loss or damage arising out of your failure to comply with any applicable laws or regulations and for your breach of the following warranties and representations:
                </p>

                <ul className={ styles.list }>
                    <li>All information provided by the consignor or its representative is complete and accurate;</li>
                    <li>The shipment is properly packed, marked, and addressed to ensure safe transportations with ordinary care in handling;</li>
                    <li>Shipment of a fragile nature are properly packed and rated with foam, pellets, or other insulating material and clearly marked as fragile with the label attached visibly thereto;

                    </li>
                </ul>

            </div>
            <div>
                <h4 className={ styles.title }>
                    RETRIEVAL PROCEDURE
                </h4>

                <ul className={ styles.list }>
                    <li>
                        Once a contract is sealed, a validation text is sent to the consignor's mobile number and email address which will contain therein the waybill number and other waybill details
                    </li><li>
                        A unique OTP (One Time Password) is sent to the consignee's mobile number and email address which will be totally exclusive and will serve as the proof of ownership on the collection of items.
                    </li><li>
                        We reserve the right to safeguard shipments once it is contracted and consignments will only be released to the carrier of the unique OTP.
                    </li>
                </ul>

            </div>



            <br />

            <br />

            <br />
        </Container>
    );
};

export default Legal;
