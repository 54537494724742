import React, { useState } from "react";

import { Container, ButtonLink } from "@components";

import styles from "../Page.module.scss";

import EcommerceForm from "./EcommerceForm";

const features = [
  `Discounted rates\n“Ship for less”`,
  `API integration with\nmerchants on their sites`,
  `Pay for packages upon\ncollection`,
  `E-wallets`,
  `Exclusive promo\noffers`,
  `Priority shipments`,
];

const Item = ({ text, num }) => (
  <div className={styles.item}>
    <span>{num.toString().padStart(2, "0")}</span>
    <p>{text}</p>
  </div>
);

const EcommerceRegister = () => {
  const [showForm, setshowForm] = useState(false);

  if (showForm) {
    return <EcommerceForm />;
  }

  return (
    <Container>
      <div className={styles.ecommerce}>
        <section className={styles.main}>
          <p className={styles.subHeading}>The best plan for your business</p>
          <h1 className={styles.heading}>E-commerce Plan</h1>

          <p>
            This service is targeted at SME businesses as we have dedicated the
            platform to ensure that businesses can get goods to their customers
            at the most affordably rates so that they can focus on expanding
            their businesses and deliver excellent customer service.
          </p>

          <ButtonLink type="button" onClick={() => setshowForm(true)}>
            Register
          </ButtonLink>
        </section>

        <aside className={styles.img} />
      </div>

      <div className={styles.featuresWrap}>
        <section className={styles.features}>
          {features.map((f, i) => (
            <Item num={i + 1} text={f} key={f} />
          ))}
        </section>
      </div>
    </Container>
  );
};

export default EcommerceRegister;
