import React from "react";
import { Table } from "../";

const list = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Channel",
    accessor: "channelId",
  },
  {
    Header: "Transaction type",
    accessor: "transactionTypeId",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
];

const TransactionsTable = ({
  data,
  wrapperClass,
  usePaginate = true,
  columns = list,
}) => {
  return (
    <div>
      <Table
        data={data}
        paginate={usePaginate}
        bordered
        columns={columns}
        wrapperClass={wrapperClass}
      />
    </div>
  );
};

export { TransactionsTable };
