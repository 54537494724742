import * as yup from "yup";

const item =
{
    itemTypeId: "",
    declared: 1,
    quantity: 1,
    weight: 1,
    amount: "",
    error: "",
    shipmentTypeId: "",

};

const initValues =
{
    taxId: "VAT",
    insuranceId: "Insurance_2",
    packagingCostId: "ABC_test2",
    //totalKilometers: "",
    deliveryTypeId: "",
    departureHubAdd: "",
    destinationHubAdd: "",
    pickupLocation: "",
    customerTypeId: "Regular",
    items:
        [
            { ...item }
        ],
    departureHubId: "",
    destinationHubId: "",
    serviceTypeId: "",
};

const schema = yup.object().shape(
    {
        taxId: yup.string().required( "Tax is required" ),
        insuranceId: yup.string().required( "Insurance is required" ),
        pickupLocation: yup.string().required( "pickupLocation is required" ),
        // totalKilometers: yup.number()
        //     .typeError( "Please enter a valid integer number > 0" )
        //     .required( "totalKilometers is required" ),
        packagingCostId: yup.string().required( "packagingCost is required" ),
        deliveryTypeId: yup.string().required( "deliveryTypeId is required" ),
        customerTypeId: yup.string().required( "customerTypeId is required" ),
        items: yup.array().of(
            yup.object().shape( {
                declared: yup.number()
                    .typeError( "Please enter a valid number" )
                    .required( "Declared is required" ),
                itemTypeId: yup.string().required( "itemTypeId is required" ),
                quantity: yup.number()
                    .typeError( "Please enter a valid number" )
                    .required( "Quantity is required" ),
                weight: yup.number()
                    .typeError( "Please enter a valid number" )
                    .required( "Weight is required" ),
                shipmentTypeId: yup.string().required( "shipmentTypeId is required" ),
            } )
        ),
        departureHubId: yup.string().required( "departureHubId is required" ),
        destinationHubId: yup.string().required( "destinationHubId is required" ),
        serviceTypeId: yup.string().required( "serviceTypeId is required" ),
    } )
    .required();


export { schema, initValues, item };
