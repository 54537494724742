import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { makeRequest } from "@utils";

import {
  Container,
  Header,
  Inputs,
  ButtonLink,
  FormContainer,
  ContactSection,
} from "@components";

import styles from "../Page.module.scss";

const schema = yup
  .object()
  .shape({
    //isBiz: yup.bool(),
    userName: yup.string().required("Username is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .nullable()
      .notRequired("Email is required")
      .email("Please input a valid email"),
    password: yup.string().required("Password is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .isValidPhone(),
    businessName: yup.string().when("isBiz", {
      is: (value) => !!value,
      then: yup.string().required("Business name is required"),
    }),
  })
  .required();

const hasError = (errors) => {
  const field = [
    "userName",
    "firstName",
    "lastName",
    "email",
    "password",
    "phoneNumber",
    "businessName",
  ];

  const err = field.reduce((acc, item) => {
    if (errors[item] && errors[item].message) {
      acc = true;
    }
    return acc;
  }, false);

  return err;
};

const SignUp = () => {
  const navigate = useNavigate();
  const [create, setCreate] = useState({
    status: "idle",
    error: null,
    data: null,
  });
  const {
    register,
    //watch,
    //clearErrors,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    const form = {
      ...values,
      customerTypeId: "Regular",
      customerGroupId: "walkin",
      sourceId: "WEB",
      //isBiz: undefined,
      isRequestingEcommerce: false,
      email: values.email || undefined,
      phoneNumber: values.phoneNumber.replace("+", ""),
    };

    setCreate({
      status: "pending",
      error: null,
      data: null,
    });

    const data = await makeRequest({
      path: `Customers/CreateCustomerAccount`,
      method: "POST",
      body: form,
    });

    if (data.error) {
      setCreate({
        status: "rejected",
        error: data.error.toString(),
        data: null,
      });
    } else {
      window.scrollTo(0, 0);

      setCreate({
        status: "resolved",
        error: null,
        data: "Customer created, you will be redirected to login in 5 seconds",
      });

      setTimeout(() => {
        navigate("/login");
      }, 5 * 1000);
    }
    //alert( JSON.stringify( form, null, 2 ) );
  };

  //const isBiz = watch("isBiz");

  return (
    <>
      <Container>
        <br />

        <Header center>Register</Header>

        <FormContainer
          message={`Let's get you up and running`}
          submitHandler={handleSubmit(onSubmit)}
          formClass={styles.formClass}
          autoComplete="off"
        >
          <br />

          {create.error && <p className={styles.formErr}>{create.error}</p>}

          {create.data && <p className={styles.success}>{create.data}</p>}

          <br />

          {/* <div className={styles.check}>
            <input
              id="isBiz"
              type="checkbox"
              {...register("isBiz", {
                onChange: () => {
                  clearErrors();
                },
              })}
            />
            <label htmlFor="isBiz" className={styles.label}>
              Register as business
            </label>
          </div>

          <br /> */}

          <Inputs
            inputProps={{
              ...register("firstName"),
              name: "firstName",
            }}
            label="First name"
            labelClass={styles.label}
            error={errors.firstName && errors.firstName?.message}
          />

          <Inputs
            inputProps={{
              ...register("lastName"),
              name: "lastName",
            }}
            label="Last name"
            labelClass={styles.label}
            error={errors.lastName && errors.lastName?.message}
          />

          <Inputs
            inputProps={{
              ...register("userName"),
              name: "userName",
            }}
            label="Username"
            labelClass={styles.label}
            error={errors.userName && errors.userName?.message}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <Inputs
                field={field}
                type="tel"
                inputProps={{
                  name: "phoneNumber",
                }}
                label="Phone number"
                labelClass={styles.label}
                error={errors.phoneNumber && errors.phoneNumber?.message}
              />
            )}
          />

          <Inputs
            inputProps={{
              ...register("email"),
              name: "email",
            }}
            label="Email"
            type="email"
            labelClass={styles.label}
            error={errors.email && errors.email?.message}
          />

          <Inputs
            inputProps={{
              ...register("password"),
              name: "password",
              autoComplete: "new-password",
            }}
            type="password"
            label="Password"
            labelClass={styles.label}
            error={errors.password && errors.password?.message}
          />

          {/* {isBiz ? (
            <Inputs
              inputProps={{
                ...register("businessName"),
                name: "businessName",
              }}
              label="Business name"
              labelClass={styles.label}
              error={errors.businessName && errors.businessName?.message}
            />
          ) : null} */}

          <ButtonLink
            disabled={create.status === "pending" || hasError(errors)}
            className={styles.submit}
          >
            Sign up
          </ButtonLink>

          <br />

          <p>
            Already have an account ? &nbsp;
            <NavLink className={styles.reg} to="/login">
              Sign in
            </NavLink>
          </p>
        </FormContainer>
      </Container>

      <ContactSection />
    </>
  );
};

export default SignUp;
